import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import { colors, Select, Spinner } from 'react-chui';
import { useFlags } from 'launchdarkly-react-client-sdk';

import FileRouteTable from './fileRoutes/FileRouteTable';
import { As2FileRoute } from './fileRoutes/FileRoutes';
import EditableField from '../../../components/EditableField';
import EditableTextInput from '../../../components/EditableTextInput';
import EditableIdSelect from './EditableIdSelect';
import ConnectionToggle from './ConnectionToggle';
import EditableUrl from './EditableUrl';
import ConfigurationLabel from './ConfigurationLabel';
import UploadCertificate from './UploadCertificate';
import ExportDetails from './ExportDetails';
import { ChubTable, ChubTableHeadCell, ChubTableBody } from '../../../styles/table';
import { AS2_CONNECTION_TYPE } from '../../../util/commerceHubInfo';
import { ChubButton, ChubP, chubStandards, selectableElement, BoldLabel } from '../../../styles/typography';
import { CollapseIcon, ExpandIcon } from '../../../styles/icons';
import CollapsedAdvancedOptions from './CollapsedAdvancedOptions';
import ExpandedAdvancedOptions from './ExpandedAdvancedOptions';
import { useNavigate } from 'react-router-dom';
import useEnvironments from '../environments.hook';
import IpInfoBubble from '../../../components/IpInfoBubble';

const EditableFieldRow = glamorous('div', { displayName: 'EditableFieldRow' })({
  padding: '20px 0',
});

const Configuration = glamorous('div', { displayName: 'Configuration' })({
  margin: '20px',
});

const RouteTable = glamorous(ChubTable, { diplayName: 'RouteTable' })({
  width: '100%',
});

const TableSection = glamorous('div', { displayName: 'TableSection' })({
  marginTop: '20px',
  maxWidth: '1200px',
  overflowX: 'scroll',
  flexGrow: 1,
});

const HideableAdvancedOptions = glamorous('div', { displayName: 'HideableAdvancedOptions' })({
  marginTop: '20px',
  marginBottom: '20px',
  maxWidth: '840px',
  overflowX: 'scroll',
  flexGrow: 1,
  padding: '10px',
  boxShadow: '0 1px 3px rgba(0, 0, 0, 0.25)',
  backgroundColor: 'white',
  borderRadius: '3px',
});

const ReadOnlySelect = glamorous(ChubP, { displayName: 'ReadOnlySelect' })(selectableElement, {
  display: 'inline-block',
  padding: '0 10px',
  fontSize: '18px',
});

const SendTestFileRouteKeySelect = glamorous(Select, { displayName: 'SendTestFileRouteKeySelect' })({
  maxWidth: '150px',
});

const SendTestFileButton = glamorous(ChubButton, { displayName: 'SendTestFileButton' })({
  margin: '0 20px',
  width: '100px',
});

const SendTestFileErrorMessage = glamorous('p', { displayName: 'SendTestFileErrorMessage' })(({ visibility }) => ({
  color: colors.chred,
  width: '215px',
  marginTop: '5px',
  marginLeft: '20px',
  fontStyle: 'italic',
  fontSize: '12px',
  lineHeight: '18px',
  visibility,
}));

const AdvancedOptionsButton = glamorous('button', { displayName: 'AdvancedOptionsButton' })({
  border: 'none',
  background: 'transparent',
  cursor: 'pointer',
  boxShadow: 'none',
  marginTop: '15px',
  marginRight: '5px',
  display: 'inline-block',
  float: 'right',
});

const FlexContainer = glamorous('div', { displayName: 'FlexContainer' })({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  gap: '16px',
  margin: '8px 0',
});

const SendTestFileContainer = glamorous('div', { displayName: 'SendTestFileContainer' })({
  outline: `2px dashed ${colors.brightblue}`,
  float: 'right',
  padding: '10px 20px 10px 10px',
});

const SendTestFileLabel = glamorous('label', { displayName: 'ConfigurationLabel' })(chubStandards, {
  fontSize: '18px',
  fontWeight: 'bold',
  marginLeft: '20px',
});

const GatewayContainer = glamorous(FlexContainer, { displayName: 'GatewayContainer' })({
  justifyContent: 'flex-start',
  boxShadow: '0 1px 3px rgba(0, 0, 0, 0.25)',
  backgroundColor: 'white',
  marginTop: '8px',
  padding: '10px',
  maxWidth: '50%',
});

const LoadingSpinner = glamorous(Spinner, {
  displayName: 'LoadingSpinner',
  withProps: {
    size: 'large',
  },
})({
  display: 'block',
  margin: '0 auto',
});

const As2Configuration = (props) => {
  const { onSendTestFile, testFileSendRequestComplete, testFileSendError } = props;
  const [advancedOptionsVisible, setAdvancedOptionsVisible] = useState(false);
  const outgoingFileRouteKeysOfSavedRoutes = Object.values(props.fileRoutes ? props.fileRoutes : {})
    .filter((fileRoute) => fileRoute?.direction?.origValue?.toLowerCase() === 'outgoing')
    .map((fileRoute) => fileRoute.applicationKey?.origValue);

  const [sendTestFileRouteKey, setSendTestFileRouteKey] = useState(outgoingFileRouteKeysOfSavedRoutes[0]);

  const onSelectSendTestFileRouteKey = ({ target: { value } }) => {
    setSendTestFileRouteKey(value);
  };

  const _versionStatusApplied = () => {
    return props.headVersionStatus === 'APPLIED';
  };

  const disableSendTestFileButtonAndHideSelect =
    props.canSave || !_versionStatusApplied() || !outgoingFileRouteKeysOfSavedRoutes.length;

  const renderSendTestFileRouteKeySelect = () => {
    if (!disableSendTestFileButtonAndHideSelect) {
      const fileRouteKeyOptions = outgoingFileRouteKeysOfSavedRoutes.map((key) => (
        <option value={key} key={key}>
          {key}
        </option>
      ));
      return (
        <div>
          <SendTestFileLabel>Choose a File Route Key: </SendTestFileLabel>
          <SendTestFileRouteKeySelect
            id='send-test-file-key-select'
            value={sendTestFileRouteKey}
            onChange={onSelectSendTestFileRouteKey}
          >
            {fileRouteKeyOptions}
          </SendTestFileRouteKeySelect>
        </div>
      );
    }
  };

  const navigate = useNavigate();

  if (testFileSendRequestComplete && !testFileSendError) {
    navigate(`/transfers/as2/page/${props.clientName}/${props.clientGUID}/10/${props.currentSection}`);
  }

  const _onChangeToggleAdvancedOptionsVisible = () => {
    setAdvancedOptionsVisible(!advancedOptionsVisible);
  };

  const _onChangeMicAlgorithm = ({ target: { value }, currentTarget: { id } }) => {
    const { onChangeMicAlgorithm } = props;
    onChangeMicAlgorithm(value, id);
  };

  const _onChangeAS2Gateway = (as2Gateway) => {
    const { onChangeAS2Gateway } = props;
    onChangeAS2Gateway(as2Gateway, id);
  };

  const _determineTestFileErrorMessage = () => {
    if (!_versionStatusApplied()) {
      return 'Version Status must be APPLIED to send a test file.';
    }
    if (props.canSave) {
      return 'Changes must be saved before a test file is sent.';
    }
    if (!outgoingFileRouteKeysOfSavedRoutes.length) {
      return 'Connection must include an outgoing file route to send a test file.';
    }
    if (testFileSendError) {
      return 'Test file failed to send.';
    }
    return '';
  };

  const _createAs2ExportObject = () => {
    const {
      as2Id,
      url,
      clientCertificate,
      clientSigningCertificateOverride,
      connectionEnabled,
      chubSystemIdentifiers,
      chubCertificateDetails,
      chubAs2IdGUID,
      micAlgorithm,
    } = props;

    const {
      alias,
      subject,
      notBefore,
      notAfter,
      signatureAlgorithm,
      status,
      serialNumber: clientSerialNumber,
    } = clientCertificate.origValue;

    const {
      alias: signingCertAlias,
      subject: signingCertSubject,
      notBefore: signingCertNotBefore,
      notAfter: signingCertNotAfter,
      signatureAlgorithm: signingCertSignatureAlgorithm,
      status: signingCertStatus,
      serialNumber: signingCertSerialNumber,
    } = clientSigningCertificateOverride.origValue;

    const displaySigningCertificateOverride = !!clientSigningCertificateOverride.origValue.certificateBytes.length;

    const {
      author,
      name,
      authority,
      certificateGUID,
      createDate,
      updateDate,
      serialNumber: chubSerialNumber,
    } = chubCertificateDetails;

    const chubSystemIdentifierName = chubSystemIdentifiers.find(
      (as2Identifier) => as2Identifier.guid === chubAs2IdGUID.origValue
    ).value;

    return {
      as2Id: as2Id.origValue,
      connectionEnabled: connectionEnabled.origValue,
      micAlgorithm: micAlgorithm.origValue,
      url: url.origValue,
      chubAs2Id: chubSystemIdentifierName,
      displaySigningCertificateOverride,
      partnerCertificate: {
        alias,
        subject,
        clientSerialNumber,
        notBefore,
        notAfter,
        signatureAlgorithm,
        status,
      },
      chubCertificate: {
        name,
        author,
        authority,
        certificateGUID,
        chubSerialNumber,
        createDate,
        updateDate,
      },
      signingCertificateOverride: {
        alias: signingCertAlias,
        subject: signingCertSubject,
        notBefore: signingCertNotBefore,
        notAfter: signingCertNotAfter,
        signatureAlgorithm: signingCertSignatureAlgorithm,
        status: signingCertStatus,
        serialNumber: signingCertSerialNumber,
      },
    };
  };

  const {
    id,
    environmentGUID,
    as2Id,
    onChangeAs2Id,
    mailboxName,
    onChangeMailboxName,
    url,
    https,
    port,
    onChangeUrl,
    onChangeChubAs2Id,
    readOnly,
    onRequestBubbleClose,
    onSigningCertificateOverrideRequestBubbleClose,
    onChangeChubCertificate,
    clientCertificate,
    clientSigningCertificateOverride,
    connectionEnabled,
    onClickViewPartnerCertificate,
    onClickViewPartnerSigningCertificateOverride,
    uploadCertificate,
    uploadPartnerSigningCertificateOverride,
    viewPartnerCertificate,
    viewPartnerSigningCertificateOverride,
    chubSystemIdentifiers,
    fileRoutesVisible,
    fileRoutes,
    toggleFileRouteVisibility,
    as2FileRouteTypes,
    onChangePort,
    onChangeHttps,
    chubCertificateGUID,
    chubAs2IdGUID,
    chubSystemCertificates,
    chubCertificateDetails,
    onClickConnectionToggle,
    onChangeFileRouteKey,
    onChangeFileRouteRegex,
    onChangeFileRouteExtension,
    onChangeFileRouteContentType,
    onChangeFileRouteAdditionalRoutingData,
    onAddAS2FileRoute,
    onDeleteAS2FileRoute,
    micAlgorithm,
    micAlgorithms,
    currentSection,
    allowDuplicateMessageIds,
    authenticationCertHref,
    authenticationCertPassword,
    securityAuthenticationPassword,
    securityAuthenticationRealm,
    securityAuthenticationType,
    securityAuthenticationUsername,
    connectionTimeout,
    sslAllowLegacyRenegotiation,
    sslCipher,
    sslMinimumProtocolVersion,
    sslMaximumProtocolVersion,
    outgoingReceiptAsyncResends,
    outgoingReceiptAsyncRetryDelay,
    outgoingReceiptAsyncTimeout,
    sendOutboundThroughAS2Forwarder,
    as2Gateway,
    onChangeAllowDuplicateMessageIds,
    onChangeAuthenticationCertHref,
    onChangeAuthenticationCertPassword,
    onChangeSecurityAuthenticationPassword,
    onChangeSecurityAuthenticationRealm,
    onChangeSecurityAuthenticationType,
    onChangeSecurityAuthenticationUsername,
    onChangeConnectionTimeout,
    onChangeSslAllowLegacyRenegotiation,
    onChangeSslCipher,
    onChangeSslMinimumProtocolVersion,
    onChangeSslMaximumProtocolVersion,
    onChangeOutgoingReceiptAsyncResends,
    onChangeOutgoingReceiptAsyncRetryDelay,
    onChangeOutgoingReceiptAsyncTimeout,
    onClickGatewayToggle,
    onClickRemoveSigningCertificateOverride,
  } = props;

  const as2ExportProps = chubCertificateDetails ? _createAs2ExportObject() : null;

  const showSendTestFileControls =
    connectionEnabled.origValue &&
    !readOnly &&
    (currentSection === 'OnbEnv' || currentSection === 'ProdEnv' || currentSection === 'PTLand');

  const { environments, isLoading: isLoadingEnvironments, error: hasEnvironmentsError } = useEnvironments();
  let environmentData = null;
  let selectedGateway = null;
  if (!isLoadingEnvironments && !hasEnvironmentsError) {
    environmentData = environments.find((x) => x.guid === environmentGUID);
    if (environmentData) {
      selectedGateway = environmentData.as2Gateways?.find(
        (x) => x.name === (as2Gateway.changed ? as2Gateway.newValue : as2Gateway.origValue)?.name
      );
    }
  }
  const showGatewayOptions = sendOutboundThroughAS2Forwarder.changed
    ? sendOutboundThroughAS2Forwarder.newValue
    : sendOutboundThroughAS2Forwarder.origValue;

  // Show warning message if a new client certificate is uploaded, an old signing certificate override was present, and the signing certificate override has not been changed from that old value.
  const showSigningCertificateWarningMessage =
    clientCertificate.newValue?.certificateBytes?.length &&
    clientSigningCertificateOverride.origValue?.certificateBytes?.length &&
    !clientSigningCertificateOverride.changed;

  const signingCertificateWarningMessage = showSigningCertificateWarningMessage
    ? 'If you intend to use the recently uploaded certificate as the signing certificate, please remove the existing override in Advanced Options.'
    : '';

  const { showNewIPsConfigSection } = useFlags();

  function getMigrateToPulseChecked(migratedToPulse) {
    console.log(migratedToPulse)
    const { origValue, newValue } = migratedToPulse || {};
    return typeof newValue === 'undefined'
      ? (typeof origValue === 'undefined' ? false : origValue)
      : newValue;
  }

  return (
    <Configuration>
      <EditableField {...{ ...connectionEnabled, readOnly }}>
        {({ ...editableInputProps }) => (
          <ConnectionToggle
            css={{ float: 'right' }}
            id={`as2-connection-toggle-${id}`}
            connectionType={AS2_CONNECTION_TYPE}
            onClick={onClickConnectionToggle}
            {...editableInputProps}
          />
        )}
      </EditableField>
      <EditableField
        {...{ ...props.migratedToPulse, readOnly: props.readOnly }}
        id={`migrate-to-pulse-field-${props.id}`}
      >
        {() => {
          const checked = getMigrateToPulseChecked(props.migratedToPulse);

          return (
            <FlexContainer css={{ justifyContent: 'flex-start', marginTop: '24px' }}>
              <ConfigurationLabel htmlFor={`migrate-to-pulse-${props.id}`}>
               Use Pulse
              </ConfigurationLabel>
              <input
                id={`migrate-to-pulse-${props.id}`}
                type="checkbox"
                checked={checked}
                disabled={props.readOnly}
                onChange={(e) => {
                  props.onClickMigrateToPulseToggle(props.id, e.target.checked);
                }}
                style={{ marginLeft: '10px', transform: 'scale(1.3)' }}
              />
            </FlexContainer>
          );
        }}
      </EditableField>
      {as2ExportProps && (
        <Fragment>
          <ExportDetails {...{ as2ExportProps, parentId: id }} />
        </Fragment>
      )}
      <EditableFieldRow>
        <EditableField {...{ ...as2Id, readOnly }} id={`as2-id-field-${id}`}>
          {({ ...editableInputProps }) => (
            <Fragment>
              <ConfigurationLabel for={`as2-id-${id}`}>AS2 ID:</ConfigurationLabel>
              <EditableTextInput
                id={`as2-id-${id}`}
                {...editableInputProps}
                onChange={onChangeAs2Id}
                usage={'as2Id'}
                medium
              />
            </Fragment>
          )}
        </EditableField>
        <EditableField {...{ ...micAlgorithm, readOnly }} id={`mic-algorithm-field-${id}`}>
          {({ origValue, newValue }) => (
            <Fragment>
              <ConfigurationLabel paddingLeft='50px' for={`mic-algorithm-${id}`}>
                Mic Algorithm:
              </ConfigurationLabel>
              {readOnly ? (
                <ReadOnlySelect>{origValue}</ReadOnlySelect>
              ) : (
                <Select onChange={_onChangeMicAlgorithm} value={newValue || origValue} id={`mic-algorithm-${id}`}>
                  {micAlgorithms.map((algorithm) => (
                    <option key={algorithm} value={algorithm}>
                      {algorithm}
                    </option>
                  ))}
                </Select>
              )}
            </Fragment>
          )}
        </EditableField>
      </EditableFieldRow>
      <EditableFieldRow>
        <EditableField {...{ ...mailboxName, readOnly }} id={`mailbox-name-field-${id}`}>
          {({ ...editableInputProps }) => (
            <Fragment>
              <ConfigurationLabel for={`mailbox-name-${id}`}>Mailbox Name:</ConfigurationLabel>
              <EditableTextInput
                id={`mailbox-name-${id}`}
                {...editableInputProps}
                onChange={onChangeMailboxName}
                usage={'mailboxName'}
                medium
              />
            </Fragment>
          )}
        </EditableField>
        {showSendTestFileControls && (
          <SendTestFileContainer>
            <SendTestFileLabel>Test File:</SendTestFileLabel>
            {renderSendTestFileRouteKeySelect()}
            <SendTestFileButton
              id={`send-test-file-button-${id}`}
              onClick={() => onSendTestFile(sendTestFileRouteKey)}
              disabled={disableSendTestFileButtonAndHideSelect}
            >
              Send
            </SendTestFileButton>
            <br />
            <SendTestFileErrorMessage id={`send-test-file-error-field-${id}`}>
              {_determineTestFileErrorMessage()}
            </SendTestFileErrorMessage>
          </SendTestFileContainer>
        )}
      </EditableFieldRow>
      <EditableFieldRow>
        <EditableUrl {...{ url, https, port, onChangeUrl, onChangePort, onChangeHttps, readOnly, parentId: id }} />
        <EditableField {...{ ...clientCertificate, readOnly }} id={`upload-certificate-field-${id}`}>
          {({ ...editableInputProps }) => (
            <Fragment>
              <ConfigurationLabel paddingLeft='85px'>Partner Certificate:</ConfigurationLabel>
              <UploadCertificate
                {...{
                  ...editableInputProps,
                  onRequestBubbleClose,
                  uploadCertificate,
                  onClickViewPartnerCertificate,
                  viewPartnerCertificate,
                  warningMessage: signingCertificateWarningMessage,
                  parentId: id,
                  padding: '3px 0px 0px 0px',
                }}
              />
            </Fragment>
          )}
        </EditableField>
      </EditableFieldRow>
      <EditableFieldRow>
        <EditableField {...{ ...chubAs2IdGUID, readOnly }} id={`chub-as2-id-field-${id}`}>
          {({ ...editableInputProps }) => (
            <Fragment>
              <ConfigurationLabel for={`chub-as2-id-${id}`}>Chub AS2 ID:</ConfigurationLabel>
              <EditableIdSelect
                id={`chub-as2-id-${id}`}
                {...editableInputProps}
                onChange={onChangeChubAs2Id}
                values={chubSystemIdentifiers}
              />
            </Fragment>
          )}
        </EditableField>
        <EditableField {...{ ...chubCertificateGUID, readOnly }} id={`chub-certificate-field-${id}`}>
          {({ ...editableInputProps }) => (
            <Fragment>
              <ConfigurationLabel>Chub Certificate:</ConfigurationLabel>
              <EditableIdSelect
                id={`chub-certificate-${id}`}
                {...editableInputProps}
                onChange={onChangeChubCertificate}
                values={chubSystemCertificates}
              />
            </Fragment>
          )}
        </EditableField>
      </EditableFieldRow>
      {showNewIPsConfigSection && (
        <EditableFieldRow css={{ marginTop: '24px' }}>
          <EditableField {...{ ...sendOutboundThroughAS2Forwarder, readOnly }}>
            {({ ...editableInputProps }) => (
              <FlexContainer css={{ justifyContent: 'flex-start' }}>
                <ConfigurationLabel for={`send-outbound-through-as2forwarder-toggle-${id}`}>
                  Use New IPs (via AWS)
                  <IpInfoBubble environmentGUID={environmentGUID} environmentName={currentSection} protocol='as2' />
                </ConfigurationLabel>
                <ConnectionToggle
                  id={`send-outbound-through-as2forwarder-toggle-${id}`}
                  connectionType={AS2_CONNECTION_TYPE}
                  onClick={onClickGatewayToggle}
                  {...editableInputProps}
                />
              </FlexContainer>
            )}
          </EditableField>

          {showGatewayOptions && (
            <EditableField {...{ ...as2Gateway, readOnly }} id={`as2-gateway-field-${id}`}>
              {({ origValue }) => (
                <GatewayContainer>
                  <FlexContainer css={{ alignItems: 'center' }}>
                    <BoldLabel for={`as2-gateways-list-${id}`}>Available AS2 Gateways:</BoldLabel>
                    {readOnly ? (
                      <ReadOnlySelect>{origValue?.name}</ReadOnlySelect>
                    ) : environmentData ? (
                      <Select
                        onChange={({ target: { value } }) =>
                          _onChangeAS2Gateway(environmentData.as2Gateways?.find((x) => x.name === value))
                        }
                        value={selectedGateway?.name}
                        id={`as2-gateways-list-${id}`}
                      >
                        <option hidden disabled selected value>
                          -- select gateway --
                        </option>
                        {environmentData.as2Gateways.map((x) => (
                          <option key={x.name} value={x.name}>
                            {x.name}
                          </option>
                        ))}
                      </Select>
                    ) : (
                      <LoadingSpinner />
                    )}
                  </FlexContainer>
                </GatewayContainer>
              )}
            </EditableField>
          )}
        </EditableFieldRow>
      )}

      <HideableAdvancedOptions>
        <AdvancedOptionsButton
          data-name='advancedOptions-view-toggle'
          id={`advancedOptions-view-toggle-${id}`}
          onClick={_onChangeToggleAdvancedOptionsVisible}
        >
          {advancedOptionsVisible ? <CollapseIcon /> : <ExpandIcon />}
        </AdvancedOptionsButton>
        {advancedOptionsVisible ? (
          <ExpandedAdvancedOptions
            {...{
              allowDuplicateMessageIds,
              authenticationCertHref,
              authenticationCertPassword,
              chubSystemCertificates,
              securityAuthenticationPassword,
              securityAuthenticationRealm,
              securityAuthenticationType,
              securityAuthenticationUsername,
              connectionTimeout,
              sslAllowLegacyRenegotiation,
              sslCipher,
              sslMinimumProtocolVersion,
              sslMaximumProtocolVersion,
              outgoingReceiptAsyncResends,
              outgoingReceiptAsyncRetryDelay,
              outgoingReceiptAsyncTimeout,
              onChangeAllowDuplicateMessageIds,
              onChangeAuthenticationCertHref,
              onChangeAuthenticationCertPassword,
              onChangeSecurityAuthenticationPassword,
              onChangeSecurityAuthenticationRealm,
              onChangeSecurityAuthenticationType,
              onChangeSecurityAuthenticationUsername,
              onChangeConnectionTimeout,
              onChangeSslAllowLegacyRenegotiation,
              onChangeSslCipher,
              onChangeSslMinimumProtocolVersion,
              onChangeSslMaximumProtocolVersion,
              onChangeOutgoingReceiptAsyncResends,
              onChangeOutgoingReceiptAsyncRetryDelay,
              onChangeOutgoingReceiptAsyncTimeout,
              onSigningCertificateOverrideRequestBubbleClose,
              onClickViewPartnerSigningCertificateOverride,
              viewPartnerSigningCertificateOverride,
              uploadPartnerSigningCertificateOverride,
              clientSigningCertificateOverride,
              onClickRemoveSigningCertificateOverride,
              readOnly,
              parentId: id,
            }}
          />
        ) : (
          <CollapsedAdvancedOptions />
        )}
      </HideableAdvancedOptions>

      <FileRouteTable
        connectionType={AS2_CONNECTION_TYPE}
        onAddFileRoute={onAddAS2FileRoute}
        {...{ fileRoutes, fileRoutesVisible, toggleFileRouteVisibility, readOnly, as2FileRouteTypes, parentId: id }}
      >
        {() => (
          <TableSection>
            <RouteTable>
              <thead>
                <tr>
                  <ChubTableHeadCell width='90px'>Application</ChubTableHeadCell>
                  <ChubTableHeadCell width='90px'>Application Code</ChubTableHeadCell>
                  <ChubTableHeadCell width='17%'>Name</ChubTableHeadCell>
                  <ChubTableHeadCell width='15%'>Key</ChubTableHeadCell>
                  <ChubTableHeadCell width='10%'>Direction</ChubTableHeadCell>
                  <ChubTableHeadCell width='10%'>Additional Routing Data</ChubTableHeadCell>
                  <ChubTableHeadCell width='10%'>Regex</ChubTableHeadCell>
                  <ChubTableHeadCell width='10%'>Extension</ChubTableHeadCell>
                  <ChubTableHeadCell width='10%'>Content Type</ChubTableHeadCell>
                  <ChubTableHeadCell width='3%' />
                </tr>
              </thead>
              <ChubTableBody>
                {Object.keys(fileRoutes).map(
                  (fileRouteGUID) =>
                    !fileRoutes[fileRouteGUID].deleted && (
                      <As2FileRoute
                        key={fileRouteGUID}
                        parentId={id}
                        onDeleteFileRoute={onDeleteAS2FileRoute}
                        {...{
                          ...fileRoutes[fileRouteGUID],
                          fileRouteGUID,
                          readOnly,
                          as2FileRouteTypes,
                          onChangeFileRouteKey,
                          onChangeFileRouteRegex,
                          onChangeFileRouteExtension,
                          onChangeFileRouteContentType,
                          onChangeFileRouteAdditionalRoutingData,
                        }}
                      />
                    )
                )}
              </ChubTableBody>
            </RouteTable>
          </TableSection>
        )}
      </FileRouteTable>
    </Configuration>
  );
};
export default As2Configuration;
As2Configuration.displayName = 'As2Configuration';
As2Configuration.propTypes = {
  id: PropTypes.string,
  environmentGUID: PropTypes.string,
  as2Id: PropTypes.object,
  onChangeAs2Id: PropTypes.func,
  mailboxName: PropTypes.object,
  onChangeMailboxName: PropTypes.func,
  url: PropTypes.object,
  https: PropTypes.object,
  port: PropTypes.object,
  micAlgorithm: PropTypes.object,
  onChangeUrl: PropTypes.func,
  onChangePort: PropTypes.func,
  onChangeHttps: PropTypes.func,
  onChangeMicAlgorithm: PropTypes.func,
  micAlgorithms: PropTypes.array,
  chubAs2IdGUID: PropTypes.object,
  onChangeChubAs2Id: PropTypes.func,
  chubCertificateGUID: PropTypes.object,
  onChangeChubCertificate: PropTypes.func,
  clientCertificate: PropTypes.object,
  clientSigningCertificateOverride: PropTypes.object,
  chubCertificateDetails: PropTypes.object,
  connectionEnabled: PropTypes.object,
  readOnly: PropTypes.bool,
  viewPartnerCertificate: PropTypes.bool,
  viewPartnerSigningCertificateOverride: PropTypes.bool,
  onClickViewPartnerCertificate: PropTypes.func,
  onClickViewPartnerSigningCertificateOverride: PropTypes.func,
  onRequestBubbleClose: PropTypes.func,
  onSigningCertificateOverrideRequestBubbleClose: PropTypes.func,
  uploadCertificate: PropTypes.func,
  chubSystemIdentifiers: PropTypes.array,
  as2FileRouteTypes: PropTypes.object,
  toggleFileRouteVisibility: PropTypes.func,
  fileRoutesVisible: PropTypes.bool,
  fileRoutes: PropTypes.object,
  chubSystemCertificates: PropTypes.array,
  onClickConnectionToggle: PropTypes.func,
  onChangeFileRouteKey: PropTypes.func,
  onChangeFileRouteRegex: PropTypes.func,
  onChangeFileRouteExtension: PropTypes.func,
  onChangeFileRouteContentType: PropTypes.func,
  onChangeFileRouteAdditionalRoutingData: PropTypes.func,
  onAddAS2FileRoute: PropTypes.func,
  onDeleteAS2FileRoute: PropTypes.func,
  onSendTestFile: PropTypes.func,
  currentSection: PropTypes.string,
  canSave: PropTypes.bool,
  headVersionStatus: PropTypes.string,
  allowDuplicateMessageIds: PropTypes.object,
  authenticationCertHref: PropTypes.object,
  authenticationCertPassword: PropTypes.object,
  securityAuthenticationPassword: PropTypes.object,
  securityAuthenticationRealm: PropTypes.object,
  securityAuthenticationType: PropTypes.object,
  securityAuthenticationUsername: PropTypes.object,
  connectionTimeout: PropTypes.object,
  sslAllowLegacyRenegotiation: PropTypes.object,
  sslCipher: PropTypes.object,
  sslMinimumProtocolVersion: PropTypes.object,
  sslMaximumProtocolVersion: PropTypes.object,
  outgoingReceiptAsyncResends: PropTypes.object,
  outgoingReceiptAsyncRetryDelay: PropTypes.object,
  outgoingReceiptAsyncTimeout: PropTypes.object,
  sendOutboundThroughAS2Forwarder: PropTypes.object,
  as2Gateway: PropTypes.object,
  onChangeAllowDuplicateMessageIds: PropTypes.func,
  onChangeAuthenticationCertHref: PropTypes.func,
  onChangeAuthenticationCertPassword: PropTypes.func,
  onChangeSecurityAuthenticationPassword: PropTypes.func,
  onChangeSecurityAuthenticationRealm: PropTypes.func,
  onChangeSecurityAuthenticationType: PropTypes.func,
  onChangeSecurityAuthenticationUsername: PropTypes.func,
  onChangeConnectionTimeout: PropTypes.func,
  onChangeSslAllowLegacyRenegotiation: PropTypes.func,
  onChangeSslCipher: PropTypes.func,
  onChangeSslMinimumProtocolVersion: PropTypes.func,
  onChangeSslMaximumProtocolVersion: PropTypes.func,
  onChangeOutgoingReceiptAsyncResends: PropTypes.func,
  onChangeOutgoingReceiptAsyncRetryDelay: PropTypes.func,
  onChangeOutgoingReceiptAsyncTimeout: PropTypes.func,
  onClickGatewayToggle: PropTypes.func,
  onChangeAS2Gateway: PropTypes.func,
  onClickRemoveSigningCertificateOverride: PropTypes.func,
  uploadPartnerSigningCertificateOverride: PropTypes.func,
  advancedOptionsVisible: PropTypes.bool,
  testFileSendRequestComplete: PropTypes.bool,
  testFileSendError: PropTypes.string,
  clientName: PropTypes.string,
  clientGUID: PropTypes.string,
  migratedToPulse: PropTypes.object,
  onClickMigrateToPulseToggle: PropTypes.func,
};

As2Configuration.defaultProps = {
  readOnly: true,
  fileRoutesVisible: false,
  as2FileRouteTypes: [],
  // migratedToPulse: {
  //   origValue: false,
  //   newValue: false,
  //   changed: false
  // },
};
