import { ActionCreators as UndoActionCreators } from 'redux-undo';
import configurationCanSave from '../reducers/configurationViewModelReducers/configurationCanSave';
import {
  CONFIGURATION_DATA_REQUEST_FAILED,
  FETCH_CONFIGURATION_VIEW_MODEL_COMPLETED,
  FETCH_CONFIGURATION_VIEW_MODEL_LOADING,
  FETCH_GENERATED_SFTP_PASSWORD_FAILED,
  RECEIVE_CONFIGURATION_VIEW_MODEL,
  RECEIVE_CONFIGURATION_VIEW_MODEL_UNDOABLE,
  SAVE_CONFIGURATION_VIEW_MODEL_COMPLETED,
  SAVE_CONFIGURATION_VIEW_MODEL_LOADING,
  TOGGLE_VIEW_PARTNER_CERTIFICATE,
  SET_CLIENT_INFO,
  SET_CURRENT_SECTION,
  SET_CURRENT_SECTION_UNDOABLE,
  RESET_SECTION_CAN_SAVE,
  SET_CONFIGURATION_URL,
  SET_PARTNER_SFTP_CONFIGURATION_URL,
  SET_CONFIGURATION_PORT,
  SET_CONFIGURATION_HTTPS,
  SET_CHUB_SYSTEM_IDENTIFIER_LIST,
  SET_CHUB_SYSTEM_IDENTIFIER,
  SET_AS2_FILE_ROUTE_LIST,
  SET_SFTP_FILE_ROUTE_LIST,
  SET_PARTNER_SFTP_FILE_ROUTE_LIST,
  SET_AS2_ID,
  SET_MAILBOX_NAME,
  TOGGLE_FILE_ROUTE_VISIBILITY,
  SET_CHUB_SYSTEM_CERTIFICATE_LIST,
  SET_CHUB_SYSTEM_CERTIFICATE,
  SET_FILE_ROUTE_KEY,
  SET_CONNECTION_ENABLED,
  SET_FILE_ROUTE_REGEX,
  SET_FILE_ROUTE_EXTENSION,
  SET_FILE_ROUTE_CONTENT_TYPE,
  SET_FILE_ROUTE_ADDITIONAL_ROUTING_DATA,
  SET_FILE_ROUTE_MD5_VERIFIED,
  SET_SFTP_USERNAME,
  SET_PARTNER_SFTP_USERNAME,
  SET_SUB_PATH,
  ADD_SUB_PATH,
  DELETE_SUB_PATH,
  ADD_NEW_AS2_FILE_ROUTE,
  ADD_NEW_SFTP_FILE_ROUTE,
  ADD_NEW_PARTNER_SFTP_FILE_ROUTE,
  DELETE_FILE_ROUTE,
  ADD_AS2_CONNECTION,
  ADD_SFTP_CONNECTION,
  ADD_PARTNER_SFTP_CONNECTION,
  COPY_SECTION,
  SET_COPYABLE_ENVIRONMENTS,
  SET_MIC_ALGORITHM_LIST,
  SET_CONFIGURATION_MICALGORITHM,
  SET_VERSION_NAME,
  FETCH_RELATIONSHIPS_LOADING,
  FETCH_RELATIONSHIPS_COMPLETED,
  FETCH_RELATIONSHIPS_FAILED,
  TOGGLE_SHOW_RELATIONSHIPS,
  RECEIVE_RELATIONSHIPS,
  ADD_RELATIONSHIP,
  SEND_TEST_FILE_COMPLETED,
  SEND_TEST_FILE_FAILED,
  SET_ALLOW_DUPLICATE_MESSAGE_IDS,
  SET_AUTHENTICATION_CERT_HREF,
  SET_AUTHENTICATION_CERT_PASSWORD,
  SET_SECURITY_AUTHENTICATION_PASSWORD,
  SET_SECURITY_AUTHENTICATION_REALM,
  SET_SECURITY_AUTHENTICATION_TYPE,
  SET_SECURITY_AUTHENTICATION_USERNAME,
  SET_CONNECTION_TIMEOUT,
  SET_SSL_ALLOW_LEGACY_RENEGOTIATION,
  SET_SSL_CIPHER,
  SET_SSL_MINIMUM_PROTOCOL_VERSION,
  SET_SSL_MAXIMUM_PROTOCOL_VERSION,
  SET_OUTGOING_RECEIPT_ASYNC_RESENDS,
  SET_OUTGOING_RECEIPT_ASYNC_RETRY_DELAY,
  SET_OUTGOING_RECEIPT_ASYNC_TIMEOUT,
  DELETE_AS2_CONNECTION,
  DELETE_PARTNER_SFTP_CONNECTION,
  RESET_STATE,
  SEND_TEST_FILE_PROCESSING,
  SET_AS2GATEWAY_ENABLED,
  SET_AS2GATEWAY,
  SET_PARTNER_SFTP_CONFIGURATION_PORT,
  SET_PARTNER_SFTP_PASSWORD,
  SET_CLIENT_AUTH_CERT,
  SET_CLIENT_AUTH_CERT_PASSWORD,
  SET_CLIENT_AUTH_PRIVATE_KEY_FILE,
  SET_CLIENT_AUTH_PRIVATE_KEY_FILE_PASSWORD,
  TOGGLE_VIEW_PARTNER_SIGNING_CERTIFICATE_OVERRIDE,
} from '../ClientConfigurationActionTypes';
import { PARTNER_SFTP_CONNECTION_TYPE } from '../../../util/commerceHubInfo';
import { fetchClient } from '../../../util/fetchClient';

export function fetchInitialData(clientGUID, versionGUID, environmentGUID) {
  return (dispatch) => {
    /* This dispatch is valuable so we can reset to the initial state when we click the "View Parent Version" button.
         Even if all the data except isLoading is set later in this function (which takes time to verify), this should
         be more resilient to future changes than resetting select parts of the initial state. */
    dispatch(resetState());
    const versionGUIDPath = versionGUID ? `/${versionGUID}` : '';
    const initialDataUrl = `/commercehub/configuration/clientAndSystemData/${clientGUID}${versionGUIDPath}`;
    const clientDataPromise = fetchClient.get(initialDataUrl).then((response) => response.data);
    const environmentsPromise = fetchClient
      .get('/admin/environments')
      .then((response) => response.data)
      .then((data) =>
        data
          ? data.map((x) => ({
              name: x.environmentName.origValue,
              guid: x.environmentGUID.origValue,
              as2Gateways: x.as2Gateways.origValue,
            }))
          : []
      );
    return Promise.all([clientDataPromise, environmentsPromise])
      .then(([data, environmentList]) => {
        const {
          copyableEnvironments,
          clientConfigurationInfo,
          chubSystemIdentifiers,
          chubSystemCertificates,
          micAlgorithms,
          as2FileRouteTypes,
          sftpFileRouteTypes,
          partnerSftpFileRouteTypes,
        } = data;
        dispatch(setClientInfo(clientConfigurationInfo.clientName, clientConfigurationInfo.clientGUID));
        dispatch(fetchConfigurationViewModel(versionGUID, environmentGUID));
        dispatch(setCurrentSection(clientConfigurationInfo.currentSection));
        dispatch(setChubSystemIdentifierList(chubSystemIdentifiers));
        dispatch(setAS2FileRouteList(as2FileRouteTypes));
        dispatch(setSFTPFileRouteList(sftpFileRouteTypes));
        dispatch(setPartnerSFTPFileRouteList(partnerSftpFileRouteTypes));
        dispatch(setChubSystemCertificateList(chubSystemCertificates));
        dispatch(setCopyableEnvironments(copyableEnvironments, environmentList));
        dispatch(setMicAlgorithmList(micAlgorithms));
      })
      .catch((error) => dispatch(configurationDataRequestFailed(error)));
  };
}

export function showRelationships() {
  return (dispatch, getState) => {
    const {
      undoableConfigurationViewModel: {
        present: { clientName },
      },
    } = getState();
    dispatch(fetchRelationshipsLoading());
    dispatch(toggleShowRelationships());
    return fetchClient
      .get(`/commercehub/configuration/relationships/${clientName}`)
      .then((response) => response.data)
      .then((relationshipsFromServer) => {
        if (relationshipsFromServer.error) {
          dispatch(fetchRelationshipsFailed(relationshipsFromServer.error));
        } else {
          dispatch(receiveRelationships(relationshipsFromServer));
          dispatch(fetchRelationshipsCompleted());
        }
      })
      .catch((error) => dispatch(fetchRelationshipsFailed(error)));
  };
}

function resetState() {
  return {
    type: RESET_STATE,
  };
}

function fetchRelationshipsLoading() {
  return {
    type: FETCH_RELATIONSHIPS_LOADING,
  };
}

function fetchRelationshipsCompleted() {
  return {
    type: FETCH_RELATIONSHIPS_COMPLETED,
  };
}

function fetchRelationshipsFailed(error) {
  return {
    type: FETCH_RELATIONSHIPS_FAILED,
    error,
  };
}

function trimId(id) {
  const guidStartPos = -36;
  if (!id) {
    return '';
  }
  return id.substr(guidStartPos);
}

export function toggleShowRelationships() {
  return {
    type: TOGGLE_SHOW_RELATIONSHIPS,
  };
}

export function receiveRelationships(relationships) {
  return {
    type: RECEIVE_RELATIONSHIPS,
    relationships,
  };
}

export function addRelationship(relationshipFileRoutes, useAs2ForwarderAsDefault) {
  return {
    type: ADD_RELATIONSHIP,
    relationshipFileRoutes,
    useAs2ForwarderAsDefault,
  };
}

export function fetchCertificateInformation(certificateFile, id) {
  const guid = trimId(id);
  return (dispatch, getState) => {
    dispatch(fetchConfigurationViewModelLoading());
    const formData = new FormData();
    formData.append('certificate', certificateFile);

    return fetchClient
      .post('/commercehub/configuration/certificateInfo', formData, {
        headers: { 'Content-Type': 'multipart/form-data'},
      })
      .then((response) => response.data)
      .then(({ serverMessages = [], ...certificateInfo }) => {
        const viewModel = getState().undoableConfigurationViewModel.present;

        const sections = viewModel.sections;
        const currentSection = viewModel.currentSection;

        const as2ConnectionList = sections[currentSection].as2Connections;
        const as2Connection = findAs2ConnectionByGuid(as2ConnectionList, guid);

        const newAs2Connection = {
          ...as2Connection,
          clientCertificate: {
            ...as2Connection.clientCertificate,
            serverMessages,
          },
        };

        const updatedCurrentSection = updateCurrentSectionWithCert(
          sections[currentSection],
          as2ConnectionList,
          newAs2Connection,
          guid,
          certificateInfo
        );

        dispatch(fetchConfigurationViewModelCompleted());
        dispatch(
          receiveConfigurationViewModelUndoable({
            ...viewModel,
            sections: {
              ...sections,
              [currentSection]: updatedCurrentSection,
            },
          })
        );
      })
      .catch((error) => dispatch(configurationDataRequestFailed(error)));
  };
}

export function fetchPartnerSigningCertificateOverrideInformation(certificateFile, id) {
  const guid = trimId(id);
  return (dispatch, getState) => {
    dispatch(fetchConfigurationViewModelLoading());
    const formData = new FormData();
    formData.append('certificate', certificateFile);

    return fetchClient
        .post('/commercehub/configuration/certificateInfo', formData, {
          headers: { 'Content-Type': 'multipart/form-data'},
        })
        .then((response) => response.data)
        .then(({ serverMessages = [], ...certificateInfo }) => {
          const viewModel = getState().undoableConfigurationViewModel.present;

          const sections = viewModel.sections;
          const currentSection = viewModel.currentSection;

          const as2ConnectionList = sections[currentSection].as2Connections;
          const as2Connection = findAs2ConnectionByGuid(as2ConnectionList, guid);

          const newAs2Connection = {
            ...as2Connection,
          clientSigningCertificateOverride: {
              ...as2Connection.clientSigningCertificateOverride,
              serverMessages,
            },
          };

          const updatedCurrentSection = updateCurrentSectionWithPartnerSigningCertOverride(
              sections[currentSection],
              as2ConnectionList,
              newAs2Connection,
              guid,
              certificateInfo
          );

          dispatch(fetchConfigurationViewModelCompleted());
          dispatch(
              receiveConfigurationViewModelUndoable({
                ...viewModel,
                sections: {
                  ...sections,
                  [currentSection]: updatedCurrentSection,
                },
              })
          );
        })
        .catch((error) => dispatch(configurationDataRequestFailed(error)));
  };
}

export function removePartnerSigningCertificateOverride(id) {
    const guid = trimId(id);
    return (dispatch, getState) => {
        const viewModel = getState().undoableConfigurationViewModel.present;

        const sections = viewModel.sections;
        const currentSection = viewModel.currentSection;

        const as2ConnectionList = sections[currentSection].as2Connections;
        const as2Connection = findAs2ConnectionByGuid(as2ConnectionList, guid);

        const newAs2Connection = {
            ...as2Connection,
            clientSigningCertificateOverride: {
                ...as2Connection.clientSigningCertificateOverride,
            },
        };

        const updatedCurrentSection = updateCurrentSectionToRemovePartnerSigningCertOverride(
            sections[currentSection],
            as2ConnectionList,
            newAs2Connection,
            guid,
        );

        dispatch(
            receiveConfigurationViewModelUndoable({
                ...viewModel,
                sections: {
                    ...sections,
                    [currentSection]: updatedCurrentSection,
                },
            })
        );
    };
}

export function fetchConfigurationViewModel(versionGUID, environmentGUID) {
  return (dispatch, getState) => {
    const {
      undoableConfigurationViewModel: {
        present: { clientGUID },
      },
    } = getState();
    let fetchPath = `/commercehub/configuration/fetch/${clientGUID}`;
    let showVersionName = false;
    if (typeof versionGUID !== 'undefined') {
      showVersionName = true;
      fetchPath += `/${versionGUID}`;
      if (typeof environmentGUID !== 'undefined') {
        fetchPath += `/${environmentGUID}`;
      }
    }

    return fetchClient
      .get(`${fetchPath}`)
      .then((response) => response.data)
      .then((configurationViewModel) => {
        dispatch(fetchConfigurationViewModelCompleted());
        dispatch(receiveConfigurationViewModel(configurationViewModel, showVersionName));
        dispatch(resetSectionCanSave());
        dispatch(UndoActionCreators.clearHistory());
      })
      .catch((error) => dispatch(configurationDataRequestFailed(error)));
  };
}

export function saveConfigurationViewModel() {
  return (dispatch, getState) => {
    dispatch(setVersionName(''));
    const {
      undoableConfigurationViewModel: { present },
    } = getState();
    dispatch(saveConfigurationViewModelLoading());

    return fetchClient
      .post(`/commercehub/configuration`, present)
      .then((response) => response.data)
      .then((configurationViewModel) => {
        dispatch(saveConfigurationViewModelCompleted());
        dispatch(receiveConfigurationViewModel(configurationViewModel));
        dispatch(resetSectionCanSave(true));
        dispatch(UndoActionCreators.clearHistory());
      })
      .catch((error) => dispatch(configurationDataRequestFailed(error)));
  };
}

export function fetchGeneratedSftpPasswordUndoable() {
  return (dispatch, getState) => {
    const {
      undoableConfigurationViewModel: {
        present: { sections, currentSection },
      },
    } = getState();

    if (sections[currentSection].sftpConnection) {
      return generateSftpPassword(getState)
        .then((updatedCurrentSection) => {
          const {
            undoableConfigurationViewModel: { present: viewModel },
          } = getState();
          updatedCurrentSection.canSave = configurationCanSave(updatedCurrentSection);

          dispatch(
            receiveConfigurationViewModelUndoable({
              ...viewModel,
              sections: {
                ...viewModel.sections,
                [viewModel.currentSection]: updatedCurrentSection,
              },
            })
          );
        })
        .catch((error) => dispatch(fetchGeneratedSftpPasswordFailed(error)));
    }
    return Promise.resolve();
  };
}

export function fetchGeneratedSftpPassword() {
  return (dispatch, getState) => {
    const {
      undoableConfigurationViewModel: {
        present: { sections, currentSection },
      },
    } = getState();

    if (sections[currentSection].sftpConnection) {
      return generateSftpPassword(getState)
        .then((updatedCurrentSection) => {
          const {
            undoableConfigurationViewModel: { present: viewModel },
          } = getState();

          dispatch(
            receiveConfigurationViewModel({
              ...viewModel,
              sections: {
                ...viewModel.sections,
                [viewModel.currentSection]: updatedCurrentSection,
              },
            })
          );
        })
        .catch((error) => dispatch(fetchGeneratedSftpPasswordFailed(error)));
    }
    return Promise.resolve();
  };
}

export function sendTestFile(fileTypeId) {
  return (dispatch, getState) => {
    const {
      undoableConfigurationViewModel: {
        present: { clientName, currentSection },
      },
    } = getState();

    dispatch(sendTestFileProcessing(currentSection));

    return fetchClient
      .post(`/commercehub/configuration/transfers/as2/sendTestFile/${clientName}/${currentSection}/${fileTypeId}`)
      .then((response) => response.data)
      .then((response) => {
        if (response.success) {
          dispatch(sendTestFileCompleted(currentSection));
          dispatch(UndoActionCreators.clearHistory());
        } else {
          dispatch(sendTestFileFailed(currentSection, response.message));
        }
      })
      .catch((error) => dispatch(sendTestFileFailed(currentSection, error)));
  };
}

function generateSftpPassword(getState) {
  return fetchClient
    .get('/commercehub/configuration/sftpPassword/generate')
    .then((response) => response.data)
    .then((generatedPassword) => {
      const viewModel = getState().undoableConfigurationViewModel.present;
      return {
        ...viewModel.sections[viewModel.currentSection],
        sftpConnection: {
          ...viewModel.sections[viewModel.currentSection].sftpConnection,
          generatedPassword: generatedPassword.generatedPassword,
        },
        canSave: true,
      };
    });
}

function fetchGeneratedSftpPasswordFailed(error) {
  return {
    type: FETCH_GENERATED_SFTP_PASSWORD_FAILED,
    error,
  };
}

export function setVersionName(versionName) {
  return {
    type: SET_VERSION_NAME,
    versionName,
  };
}

export function addSubPath(fileRouteGUID, accountGUID, connectionType) {
  return {
    type: ADD_SUB_PATH,
    fileRouteGUID,
    accountGUID,
    connectionType,
  };
}

export function deleteSubPath(fileRouteGUID, subPathGUID, accountGUID, connectionType) {
  return {
    type: DELETE_SUB_PATH,
    fileRouteGUID,
    subPathGUID,
    accountGUID,
    connectionType,
  };
}

export function setCurrentSection(currentSection) {
  return {
    type: SET_CURRENT_SECTION,
    currentSection,
  };
}

export function setCurrentSectionUndoable(currentSection) {
  return {
    type: SET_CURRENT_SECTION_UNDOABLE,
    currentSection,
  };
}

export function setFileRouteKey(connectionType, fileRouteGUID, fileTypeId, mailboxGUID) {
  const guidType = connectionType === PARTNER_SFTP_CONNECTION_TYPE ? 'accountGUID' : 'mailboxGUID';
  return {
    type: SET_FILE_ROUTE_KEY,
    connectionType,
    fileRouteGUID,
    [guidType]: mailboxGUID,
    fileTypeId,
  };
}

export function setFileRouteRegex(connectionType, fileRouteGUID, regex, mailboxGUID) {
  return {
    type: SET_FILE_ROUTE_REGEX,
    connectionType,
    fileRouteGUID,
    mailboxGUID,
    regex,
  };
}

export function setSubPath(fileRouteGUID, subPathGUID, subPath, accountGUID, connectionType) {
  return {
    type: SET_SUB_PATH,
    fileRouteGUID,
    subPathGUID,
    subPath,
    accountGUID,
    connectionType,
  };
}

export function setFileRouteMd5Verified(connectionType, fileRouteGUID, md5Verified) {
  return {
    type: SET_FILE_ROUTE_MD5_VERIFIED,
    connectionType,
    fileRouteGUID,
    md5Verified,
  };
}

export function setFileRouteExtension(connectionType, fileRouteGUID, extension, mailboxGUID) {
  return {
    type: SET_FILE_ROUTE_EXTENSION,
    connectionType,
    fileRouteGUID,
    mailboxGUID,
    extension,
  };
}

export function setFileRouteContentType(connectionType, fileRouteGUID, contentType, mailboxGUID) {
  return {
    type: SET_FILE_ROUTE_CONTENT_TYPE,
    connectionType,
    fileRouteGUID,
    mailboxGUID,
    contentType,
  };
}

export function setFileRouteAdditionalRoutingData(connectionType, fileRouteGUID, additionalRoutingData, mailboxGUID) {
  const guidType = connectionType === PARTNER_SFTP_CONNECTION_TYPE ? 'accountGUID' : 'mailboxGUID';
  return {
    type: SET_FILE_ROUTE_ADDITIONAL_ROUTING_DATA,
    connectionType,
    fileRouteGUID,
    [guidType]: mailboxGUID,
    additionalRoutingData,
  };
}

export function setClientInfo(clientName, clientGUID) {
  return {
    type: SET_CLIENT_INFO,
    clientName,
    clientGUID,
  };
}

export function setChubSystemIdentifierList(chubSystemIdentifiers) {
  return {
    type: SET_CHUB_SYSTEM_IDENTIFIER_LIST,
    chubSystemIdentifiers,
  };
}

export function setAS2FileRouteList(as2FileRouteTypes) {
  return {
    type: SET_AS2_FILE_ROUTE_LIST,
    as2FileRouteTypes,
  };
}

export function setSFTPFileRouteList(sftpFileRouteTypes) {
  return {
    type: SET_SFTP_FILE_ROUTE_LIST,
    sftpFileRouteTypes,
  };
}

export function setPartnerSFTPFileRouteList(partnerSftpFileRouteTypes) {
  return {
    type: SET_PARTNER_SFTP_FILE_ROUTE_LIST,
    partnerSftpFileRouteTypes,
  };
}

export function toggleFileRouteVisibility(connectionType, guid) {
  return {
    type: TOGGLE_FILE_ROUTE_VISIBILITY,
    guid,
    connectionType,
  };
}

export function setChubSystemCertificateList(chubSystemCertificates) {
  return {
    type: SET_CHUB_SYSTEM_CERTIFICATE_LIST,
    chubSystemCertificates,
  };
}

export function toggleViewPartnerCertificate(id) {
  const guid = trimId(id);
  return {
    type: TOGGLE_VIEW_PARTNER_CERTIFICATE,
    guid,
  };
}

export function toggleViewPartnerSigningCertificateOverride(id) {
  const guid = trimId(id);
  return {
    type: TOGGLE_VIEW_PARTNER_SIGNING_CERTIFICATE_OVERRIDE,
    guid,
  };
}

export function setChubSystemIdentifier(chubSystemIdentifier, chubSystemIdentifierGUID, isModified, id) {
  const guid = trimId(id);
  return {
    type: SET_CHUB_SYSTEM_IDENTIFIER,
    chubSystemIdentifier,
    chubSystemIdentifierGUID,
    isModified,
    guid,
  };
}

export function setSFTPUsername(username) {
  return {
    type: SET_SFTP_USERNAME,
    username,
  };
}

export function setPartnerSFTPUsername(username, id) {
  const accountGUID = trimId(id);
  return {
    type: SET_PARTNER_SFTP_USERNAME,
    username,
    accountGUID,
  };
}

export function setPartnerSFTPPassword(password, id) {
  const accountGUID = trimId(id);
  return {
    type: SET_PARTNER_SFTP_PASSWORD,
    password,
    accountGUID,
  };
}

export function setClientAuthCert(clientAuthenticationCert, id) {
  const accountGUID = trimId(id);
  return {
    type: SET_CLIENT_AUTH_CERT,
    clientAuthenticationCert,
    accountGUID,
  };
}

export function setClientAuthCertPassword(clientAuthenticationCertPassword, id) {
  const accountGUID = trimId(id);
  return {
    type: SET_CLIENT_AUTH_CERT_PASSWORD,
    clientAuthenticationCertPassword,
    accountGUID,
  };
}

export function setClientAuthPrivateKeyFile(clientAuthenticationPrivateKeyFile, id) {
  const accountGUID = trimId(id);
  return {
    type: SET_CLIENT_AUTH_PRIVATE_KEY_FILE,
    clientAuthenticationPrivateKeyFile,
    accountGUID,
  };
}

export function setClientAuthenticationPrivateKeyFilePassword(clientAuthenticationPrivateKeyFilePassword, id) {
  const accountGUID = trimId(id);
  return {
    type: SET_CLIENT_AUTH_PRIVATE_KEY_FILE_PASSWORD,
    clientAuthenticationPrivateKeyFilePassword,
    accountGUID,
  };
}

export function setPartnerSFTPPort(port, id) {
  const accountGUID = trimId(id);
  return {
    type: SET_PARTNER_SFTP_CONFIGURATION_PORT,
    port,
    accountGUID,
  };
}

export function setChubSystemCertificate(chubSystemCertificate, chubSystemCertificateGUID, isModified, id) {
  const guid = trimId(id);
  return {
    type: SET_CHUB_SYSTEM_CERTIFICATE,
    chubSystemCertificate,
    chubSystemCertificateGUID,
    isModified,
    guid,
  };
}

export function copySection(destinationSectionGUID, destinationSectionName) {
  return {
    type: COPY_SECTION,
    destinationSectionGUID,
    destinationSectionName,
  };
}

export function setConnectionEnabled(isEnabled, connectionType, id) {
  const guid = trimId(id);
  return {
    type: SET_CONNECTION_ENABLED,
    isEnabled,
    connectionType,
    guid,
  };
}

export function addAS2FileRoute(mailboxGUID) {
  return {
    type: ADD_NEW_AS2_FILE_ROUTE,
    mailboxGUID,
  };
}

export function deleteFileRoute(fileRouteGUID, connectionType, mailboxGUID) {
  const guidType = connectionType === PARTNER_SFTP_CONNECTION_TYPE ? 'accountGUID' : 'mailboxGUID';
  return {
    type: DELETE_FILE_ROUTE,
    fileRouteGUID,
    [guidType]: mailboxGUID,
    connectionType,
  };
}

export function setCopyableEnvironments(copyableEnvironments, environmentList) {
  return {
    type: SET_COPYABLE_ENVIRONMENTS,
    copyableEnvironments,
    environmentList,
  };
}

export function addSFTPFileRoute() {
  return {
    type: ADD_NEW_SFTP_FILE_ROUTE,
  };
}

export function addPartnerSFTPFileRoute(accountGUID) {
  return {
    type: ADD_NEW_PARTNER_SFTP_FILE_ROUTE,
    accountGUID,
  };
}

export function setAS2Id(as2Id, id) {
  const guid = trimId(id);
  return {
    type: SET_AS2_ID,
    as2Id,
    guid,
  };
}

export function setMailboxName(mailboxName, id) {
  const guid = trimId(id);
  return {
    type: SET_MAILBOX_NAME,
    mailboxName,
    guid,
  };
}

function fetchConfigurationViewModelLoading() {
  return {
    type: FETCH_CONFIGURATION_VIEW_MODEL_LOADING,
  };
}

export function setUrl(url, id) {
  const guid = trimId(id);
  return {
    type: SET_CONFIGURATION_URL,
    url,
    guid,
  };
}

export function setPartnerSFTPUrl(url, id) {
  const accountGUID = trimId(id);
  return {
    type: SET_PARTNER_SFTP_CONFIGURATION_URL,
    url,
    accountGUID,
  };
}

export function setPort(port, id) {
  const guid = trimId(id);
  return {
    type: SET_CONFIGURATION_PORT,
    port,
    guid,
  };
}

export function setHttps(https, id) {
  const guid = trimId(id);
  return {
    type: SET_CONFIGURATION_HTTPS,
    https,
    guid,
  };
}

export function setMicAlgorithm(micAlgorithm, id) {
  const guid = trimId(id);
  return {
    type: SET_CONFIGURATION_MICALGORITHM,
    micAlgorithm,
    guid,
  };
}

export function setMicAlgorithmList(micAlgorithms) {
  return {
    type: SET_MIC_ALGORITHM_LIST,
    micAlgorithms,
  };
}

export function addAs2Connection(useAs2ForwarderAsDefault) {
  return {
    type: ADD_AS2_CONNECTION,
    useAs2ForwarderAsDefault,
  };
}

export function addSftpConnection() {
  return {
    type: ADD_SFTP_CONNECTION,
  };
}

export function addPartnerSftpConnection() {
  return {
    type: ADD_PARTNER_SFTP_CONNECTION,
  };
}

export function setAllowDuplicateMessageIds(allowDuplicateMessageIds, id) {
  const guid = trimId(id);
  return {
    type: SET_ALLOW_DUPLICATE_MESSAGE_IDS,
    allowDuplicateMessageIds,
    guid,
  };
}

export function setAuthenticationCertHref(authenticationCertificate, authenticationCertificateGUID, isModified, id) {
  const guid = trimId(id);
  return {
    type: SET_AUTHENTICATION_CERT_HREF,
    authenticationCertificate,
    authenticationCertificateGUID,
    isModified,
    guid,
  };
}

export function setAuthenticationCertPassword(authenticationCertPassword, id) {
  const guid = trimId(id);
  return {
    type: SET_AUTHENTICATION_CERT_PASSWORD,
    authenticationCertPassword,
    guid,
  };
}

export function setSecurityAuthenticationPassword(securityAuthenticationPassword, id) {
  const guid = trimId(id);
  return {
    type: SET_SECURITY_AUTHENTICATION_PASSWORD,
    securityAuthenticationPassword,
    guid,
  };
}

export function setSecurityAuthenticationRealm(securityAuthenticationRealm, id) {
  const guid = trimId(id);
  return {
    type: SET_SECURITY_AUTHENTICATION_REALM,
    securityAuthenticationRealm,
    guid,
  };
}

export function setSecurityAuthenticationType(securityAuthenticationType, id) {
  const guid = trimId(id);
  return {
    type: SET_SECURITY_AUTHENTICATION_TYPE,
    securityAuthenticationType,
    guid,
  };
}

export function setSecurityAuthenticationUsername(securityAuthenticationUsername, id) {
  const guid = trimId(id);
  return {
    type: SET_SECURITY_AUTHENTICATION_USERNAME,
    securityAuthenticationUsername,
    guid,
  };
}

export function setConnectionTimeout(connectionTimeout, id) {
  const guid = trimId(id);
  return {
    type: SET_CONNECTION_TIMEOUT,
    connectionTimeout,
    guid,
  };
}

export function setSslAllowLegacyRenegotiation(sslAllowLegacyRenegotiation, id) {
  const guid = trimId(id);
  return {
    type: SET_SSL_ALLOW_LEGACY_RENEGOTIATION,
    sslAllowLegacyRenegotiation,
    guid,
  };
}

export function setSslCipher(sslCipher, id) {
  const guid = trimId(id);
  return {
    type: SET_SSL_CIPHER,
    sslCipher,
    guid,
  };
}

export function setSslMinimumProtocolVersion(sslMinimumProtocolVersion, id) {
  const guid = trimId(id);
  return {
    type: SET_SSL_MINIMUM_PROTOCOL_VERSION,
    sslMinimumProtocolVersion,
    guid,
  };
}

export function setSslMaximumProtocolVersion(sslMaximumProtocolVersion, id) {
  const guid = trimId(id);
  return {
    type: SET_SSL_MAXIMUM_PROTOCOL_VERSION,
    sslMaximumProtocolVersion,
    guid,
  };
}

export function setOutgoingReceiptAsyncResends(outgoingReceiptAsyncResends, id) {
  const guid = trimId(id);
  return {
    type: SET_OUTGOING_RECEIPT_ASYNC_RESENDS,
    outgoingReceiptAsyncResends,
    guid,
  };
}

export function setOutgoingReceiptAsyncRetryDelay(outgoingReceiptAsyncRetryDelay, id) {
  const guid = trimId(id);
  return {
    type: SET_OUTGOING_RECEIPT_ASYNC_RETRY_DELAY,
    outgoingReceiptAsyncRetryDelay,
    guid,
  };
}

export function setOutgoingReceiptAsyncTimeout(outgoingReceiptAsyncTimeout, id) {
  const guid = trimId(id);
  return {
    type: SET_OUTGOING_RECEIPT_ASYNC_TIMEOUT,
    outgoingReceiptAsyncTimeout,
    guid,
  };
}

function fetchConfigurationViewModelCompleted() {
  return {
    type: FETCH_CONFIGURATION_VIEW_MODEL_COMPLETED,
  };
}

function receiveConfigurationViewModel(configurationViewModel, showVersionName) {
  return {
    type: RECEIVE_CONFIGURATION_VIEW_MODEL,
    configurationViewModel,
    showVersionName: typeof showVersionName === 'undefined' ? false : showVersionName,
  };
}

function receiveConfigurationViewModelUndoable(configurationViewModel) {
  return {
    type: RECEIVE_CONFIGURATION_VIEW_MODEL_UNDOABLE,
    configurationViewModel,
  };
}

function resetSectionCanSave(calledAfterSave = false) {
  return {
    type: RESET_SECTION_CAN_SAVE,
    calledAfterSave,
  };
}

function configurationDataRequestFailed(error) {
  return {
    type: CONFIGURATION_DATA_REQUEST_FAILED,
    error,
  };
}

function saveConfigurationViewModelLoading() {
  return {
    type: SAVE_CONFIGURATION_VIEW_MODEL_LOADING,
  };
}

function saveConfigurationViewModelCompleted() {
  return {
    type: SAVE_CONFIGURATION_VIEW_MODEL_COMPLETED,
  };
}

function sendTestFileProcessing(currentSection) {
  return {
    type: SEND_TEST_FILE_PROCESSING,
    currentSection,
  };
}

function sendTestFileCompleted(currentSection) {
  return {
    type: SEND_TEST_FILE_COMPLETED,
    currentSection,
  };
}

function sendTestFileFailed(currentSection, error) {
  return {
    type: SEND_TEST_FILE_FAILED,
    currentSection,
    error,
  };
}

export function setAS2GatewayEnabled(isEnabled, connectionType, id) {
  const guid = trimId(id);
  return {
    type: SET_AS2GATEWAY_ENABLED,
    isEnabled,
    guid,
  };
}

export function setAS2Gateway(as2Gateway, id) {
  const guid = trimId(id);
  return {
    type: SET_AS2GATEWAY,
    as2Gateway,
    guid,
  };
}

function updateCurrentSectionWithCert(section, as2ConnectionList, newAs2Connection, guid, certificateInfo) {
  const newAs2ConnectionList = [];
  const nonEmptyCertificateInfoKeysLen = Object.keys(certificateInfo).length !== 0;
  as2ConnectionList.forEach((connection) => {
    if (connection.mailboxGUID === guid) {
      if (nonEmptyCertificateInfoKeysLen) {
        newAs2Connection.clientCertificate.newValue = certificateInfo;
        newAs2Connection.clientCertificate.changed = true;
      }
      newAs2ConnectionList.push(newAs2Connection);
    } else {
      newAs2ConnectionList.push(connection);
    }
  });

  return {
    ...section,
    canSave: nonEmptyCertificateInfoKeysLen,
    as2Connections: newAs2ConnectionList,
  };
}

function updateCurrentSectionWithPartnerSigningCertOverride(section, as2ConnectionList, newAs2Connection, guid, certificateInfo) {
    const newAs2ConnectionList = [];
    const nonEmptyCertificateInfoKeysLen = Object.keys(certificateInfo).length !== 0;
    as2ConnectionList.forEach((connection) => {
        if (connection.mailboxGUID === guid) {
            if (nonEmptyCertificateInfoKeysLen) {
                newAs2Connection.clientSigningCertificateOverride.newValue = certificateInfo;
                newAs2Connection.clientSigningCertificateOverride.changed = true;
            }
            newAs2ConnectionList.push(newAs2Connection);
        } else {
            newAs2ConnectionList.push(connection);
        }
    });

    return {
        ...section,
        canSave: nonEmptyCertificateInfoKeysLen,
        as2Connections: newAs2ConnectionList,
    };
}

function updateCurrentSectionToRemovePartnerSigningCertOverride(section, as2ConnectionList, newAs2Connection, guid) {
    const newAs2ConnectionList = [];
    let changedFromOriginal = false;
    as2ConnectionList.forEach((connection) => {
        if (connection.mailboxGUID === guid) {
            if (newAs2Connection.clientSigningCertificateOverride.origValue.certificateBytes.length === 0) {
                newAs2Connection.clientSigningCertificateOverride.newValue = null;
                newAs2Connection.clientSigningCertificateOverride.changed = false;
            } else {
                newAs2Connection.clientSigningCertificateOverride.newValue = {
                    certificateBytes: [],
                };
                newAs2Connection.clientSigningCertificateOverride.changed = true;
                changedFromOriginal = true;
            }
            newAs2ConnectionList.push(newAs2Connection);
        } else {
            newAs2ConnectionList.push(connection);
        }
    });

    const updatedCurrentSection = {
        ...section,
        as2Connections: newAs2ConnectionList,
    }

    updatedCurrentSection.canSave = changedFromOriginal || configurationCanSave(updatedCurrentSection);

    return updatedCurrentSection;
}

function findAs2ConnectionByGuid(as2ConnectionList, guid) {
  return as2ConnectionList.find((connection) => connection.mailboxGUID === guid);
}

export function deleteAs2Connection(mailboxGUID) {
  return {
    type: DELETE_AS2_CONNECTION,
    mailboxGUID,
  };
}

export function deletePartnerSftpConnection(guid) {
  const accountGUID = trimId(guid);
  return {
    type: DELETE_PARTNER_SFTP_CONNECTION,
    accountGUID,
  };
}

export function newState(state, sections, currentSection, editedCurrentSection, guid) {
  return {
    ...state,
    sections: {
      ...sections,
      [currentSection]: {
        ...editedCurrentSection,
        canSave: configurationCanSave(
          editedCurrentSection,
          editedCurrentSection.as2ConnectionsListChanged,
          editedCurrentSection.partnerSftpConnectionsListChanged
        ),
      },
    },
  };
}

export function updateCurrentSectionAs2(section, as2ConnectionList, newAs2Connection, guid) {
  const newAs2ConnectionList = [];
  as2ConnectionList.forEach((connection) => {
    if (connection.mailboxGUID === guid) {
      newAs2ConnectionList.push(newAs2Connection);
    } else {
      newAs2ConnectionList.push(connection);
    }
  });

  return {
    ...section,
    as2Connections: newAs2ConnectionList,
  };
}

export function updateCurrentSectionPartnerSftp(section, partnerSftpConnectionList, newPartnerSftpConnection, guid) {
  const newPartnerSftpConnectionList = [];
  partnerSftpConnectionList.forEach((connection) => {
    if (connection.accountGUID === guid) {
      newPartnerSftpConnectionList.push(newPartnerSftpConnection);
    } else {
      newPartnerSftpConnectionList.push(connection);
    }
  });

  return {
    ...section,
    partnerSftpConnections: newPartnerSftpConnectionList,
  };
}

export const setMigratedToPulse = (connectionId, value) => ({
  type: 'SET_MIGRATED_TO_PULSE',
  payload: { connectionId, value },
});
