import React from 'react';
import { connect } from 'react-redux';
import { colors } from 'react-chui';

import ConfigurationBox from '../components/ConfigurationBox';
import As2Configuration from '../components/As2Configuration';
import {
  toggleViewPartnerCertificate,
  fetchCertificateInformation,
  setChubSystemIdentifier,
  setAS2Id,
  setMailboxName,
  setUrl,
  setPort,
  setHttps,
  toggleFileRouteVisibility,
  setChubSystemCertificate,
  setMicAlgorithm,
  setFileRouteKey,
  setConnectionEnabled,
  setFileRouteRegex,
  setFileRouteExtension,
  setFileRouteContentType,
  setFileRouteAdditionalRoutingData,
  addAS2FileRoute,
  deleteFileRoute,
  sendTestFile,
  setAllowDuplicateMessageIds,
  setAuthenticationCertHref,
  setAuthenticationCertPassword,
  setSecurityAuthenticationPassword,
  setSecurityAuthenticationRealm,
  setSecurityAuthenticationType,
  setSecurityAuthenticationUsername,
  setConnectionTimeout,
  setSslAllowLegacyRenegotiation,
  setSslCipher,
  setSslMinimumProtocolVersion,
  setSslMaximumProtocolVersion,
  setOutgoingReceiptAsyncResends,
  setOutgoingReceiptAsyncRetryDelay,
  setOutgoingReceiptAsyncTimeout,
  setAS2GatewayEnabled,
  setAS2Gateway,
  toggleViewPartnerSigningCertificateOverride,
  fetchPartnerSigningCertificateOverrideInformation,
  removePartnerSigningCertificateOverride,
  setMigratedToPulse
} from '../actions';

const mapStateToProps = (state) => {
  const {
    undoableConfigurationViewModel: {
      present: {
        sections,
        currentSection,
        chubSystemIdentifiers,
        chubSystemCertificates,
        micAlgorithms,
        as2FileRouteTypes,
        clientName,
        clientGUID,
      },
    },
    testFile,
  } = state;
  const { readOnly, canSave, headVersionStatus, environmentGUID } = sections[currentSection];

  const testFileData = testFile.sections[currentSection];
  const testFileSendRequestComplete = testFileData?.testFileSendRequestComplete;
  const testFileSendError = testFileData?.error;

  return {
    sectionName: 'AS2',
    backgroundColor: colors.medblue,
    textColor: 'white',
    readOnly,
    children: (props) => {
      const {
        as2Id,
        mailboxName,
        url,
        port,
        https,
        chubAs2IdGUID,
        chubCertificateDetails,
        chubCertificateGUID,
        mailboxGUID,
        micAlgorithm,
        clientCertificate,
        clientSigningCertificateOverride,
        connectionEnabled,
        fileRoutesVisible,
        fileRoutes,
        allowDuplicateMessageIds,
        authenticationCertHref,
        authenticationCertPassword,
        securityAuthenticationPassword,
        securityAuthenticationRealm,
        securityAuthenticationType,
        securityAuthenticationUsername,
        connectionTimeout,
        sslAllowLegacyRenegotiation,
        sslCipher,
        sslMinimumProtocolVersion,
        sslMaximumProtocolVersion,
        outgoingReceiptAsyncResends,
        outgoingReceiptAsyncRetryDelay,
        outgoingReceiptAsyncTimeout,
        viewPartnerCertificate,
        viewPartnerSigningCertificateOverride,
        sendOutboundThroughAS2Forwarder,
        as2Gateway,
        migratedToPulse
      } = props.connection;
      return (
        <As2Configuration
          {...{
            chubSystemIdentifiers,
            environmentGUID,
            as2Id,
            mailboxName,
            url,
            chubAs2IdGUID,
            chubCertificateGUID,
            port,
            https,
            clientCertificate,
            clientSigningCertificateOverride,
            viewPartnerCertificate,
            viewPartnerSigningCertificateOverride,
            connectionEnabled,
            readOnly,
            chubSystemCertificates,
            fileRoutesVisible,
            fileRoutes,
            toggleFileRouteVisibility,
            as2FileRouteTypes,
            chubCertificateDetails,
            micAlgorithms,
            micAlgorithm,
            sendTestFile,
            currentSection,
            canSave,
            headVersionStatus,
            allowDuplicateMessageIds,
            authenticationCertHref,
            authenticationCertPassword,
            securityAuthenticationRealm,
            securityAuthenticationType,
            securityAuthenticationUsername,
            securityAuthenticationPassword,
            connectionTimeout,
            sslAllowLegacyRenegotiation,
            sslCipher,
            sslMinimumProtocolVersion,
            sslMaximumProtocolVersion,
            outgoingReceiptAsyncResends,
            outgoingReceiptAsyncRetryDelay,
            outgoingReceiptAsyncTimeout,
            sendOutboundThroughAS2Forwarder,
            as2Gateway,
            testFileSendRequestComplete,
            testFileSendError,
            clientName,
            clientGUID,
            migratedToPulse,
            ...props,
            id: mailboxGUID,
          }}
        />
      );
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClickViewPartnerCertificate: ({ currentTarget: { id } }) => dispatch(toggleViewPartnerCertificate(id)),
    uploadCertificate: ({ target: { files }, currentTarget: { id } }) =>
      dispatch(fetchCertificateInformation(files[0], id)),
    onRequestBubbleClose: () => dispatch(toggleViewPartnerCertificate()),
    onClickViewPartnerSigningCertificateOverride: ({ currentTarget: { id } }) => dispatch(toggleViewPartnerSigningCertificateOverride(id)),
    onSigningCertificateOverrideRequestBubbleClose: () => dispatch(toggleViewPartnerSigningCertificateOverride()),
    uploadPartnerSigningCertificateOverride: ({ target: { files }, currentTarget: { id } }) =>
        dispatch(fetchPartnerSigningCertificateOverrideInformation(files[0], id)),
    onClickRemoveSigningCertificateOverride: ({ currentTarget: { id } }) => dispatch(removePartnerSigningCertificateOverride(id)),
    onChangeChubAs2Id: (...args) => dispatch(setChubSystemIdentifier(...args)),
    onChangeAs2Id: (...args) => dispatch(setAS2Id(...args)),
    onChangeMicAlgorithm: (...args) => dispatch(setMicAlgorithm(...args)),
    onChangeChubCertificate: (...args) => dispatch(setChubSystemCertificate(...args)),
    toggleFileRouteVisibility: (...args) => dispatch(toggleFileRouteVisibility(...args)),
    onChangeUrl: (...args) => dispatch(setUrl(...args)),
    onChangePort: (...args) => dispatch(setPort(...args)),
    onChangeHttps: (...args) => dispatch(setHttps(...args)),
    onChangeFileRouteKey: (...args) => dispatch(setFileRouteKey(...args)),
    onClickConnectionToggle: (...args) => dispatch(setConnectionEnabled(...args)),
    onChangeFileRouteRegex: (...args) => dispatch(setFileRouteRegex(...args)),
    onChangeFileRouteExtension: (...args) => dispatch(setFileRouteExtension(...args)),
    onChangeFileRouteContentType: (...args) => dispatch(setFileRouteContentType(...args)),
    onChangeFileRouteAdditionalRoutingData: (...args) => dispatch(setFileRouteAdditionalRoutingData(...args)),
    onAddAS2FileRoute: (...args) => dispatch(addAS2FileRoute(...args)),
    onDeleteAS2FileRoute: (...args) => dispatch(deleteFileRoute(...args)),
    onSendTestFile: (...args) => dispatch(sendTestFile(...args)),
    onChangeAllowDuplicateMessageIds: (...args) => dispatch(setAllowDuplicateMessageIds(...args)),
    onChangeAuthenticationCertHref: (...args) => dispatch(setAuthenticationCertHref(...args)),
    onChangeAuthenticationCertPassword: (...args) => dispatch(setAuthenticationCertPassword(...args)),
    onChangeSecurityAuthenticationPassword: (...args) => dispatch(setSecurityAuthenticationPassword(...args)),
    onChangeSecurityAuthenticationRealm: (...args) => dispatch(setSecurityAuthenticationRealm(...args)),
    onChangeSecurityAuthenticationType: (...args) => dispatch(setSecurityAuthenticationType(...args)),
    onChangeSecurityAuthenticationUsername: (...args) => dispatch(setSecurityAuthenticationUsername(...args)),
    onChangeConnectionTimeout: (...args) => dispatch(setConnectionTimeout(...args)),
    onChangeSslAllowLegacyRenegotiation: (...args) => dispatch(setSslAllowLegacyRenegotiation(...args)),
    onChangeSslCipher: (...args) => dispatch(setSslCipher(...args)),
    onChangeSslMinimumProtocolVersion: (...args) => dispatch(setSslMinimumProtocolVersion(...args)),
    onChangeSslMaximumProtocolVersion: (...args) => dispatch(setSslMaximumProtocolVersion(...args)),
    onChangeOutgoingReceiptAsyncResends: (...args) => dispatch(setOutgoingReceiptAsyncResends(...args)),
    onChangeOutgoingReceiptAsyncRetryDelay: (...args) => dispatch(setOutgoingReceiptAsyncRetryDelay(...args)),
    onChangeOutgoingReceiptAsyncTimeout: (...args) => dispatch(setOutgoingReceiptAsyncTimeout(...args)),
    onChangeMailboxName: (...args) => dispatch(setMailboxName(...args)),
    onClickGatewayToggle: (...args) => dispatch(setAS2GatewayEnabled(...args)),
    onChangeAS2Gateway: (...args) => dispatch(setAS2Gateway(...args)),
    onClickMigrateToPulseToggle: (connectionId, newValue) => { dispatch(setMigratedToPulse(connectionId, newValue)) },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationBox);
