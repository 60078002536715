import { DEFAULT_PORT, enforceUrlStructure, defaultPort, protocolIsHttps } from '../../util/url-structure';
import { parse } from 'url';
import { newState, updateCurrentSectionAs2 } from '../../actions';

function urlHasChanged(origValue, newValue) {
  return newValue && origValue.toLowerCase() !== newValue.toLowerCase();
}

export function toggleViewPartnerCertificate(state, action) {
  const { guid } = action;
  const { sections, currentSection } = state;
  const as2Connections = sections[currentSection].as2Connections;
  const as2Connection = as2Connections.find(
    (connection) => connection.viewPartnerCertificate || guid === connection.mailboxGUID
  );

  as2Connection.viewPartnerCertificate = !as2Connection.viewPartnerCertificate;
  const editedCurrentSection = updateCurrentSectionAs2(sections[currentSection], as2Connections, as2Connection, guid);

  return newState(state, sections, currentSection, editedCurrentSection, guid);
}

export function toggleViewPartnerSigningCertificateOverride(state, action) {
  const { guid } = action;
  const { sections, currentSection } = state;
  const as2Connections = sections[currentSection].as2Connections;
  const as2Connection = as2Connections.find(
    (connection) => connection.viewPartnerSigningCertificateOverride || guid === connection.mailboxGUID
  );

  as2Connection.viewPartnerSigningCertificateOverride = !as2Connection.viewPartnerSigningCertificateOverride;
  const editedCurrentSection = updateCurrentSectionAs2(sections[currentSection], as2Connections, as2Connection, guid);

  return newState(state, sections, currentSection, editedCurrentSection, guid);
}

export function setConfigurationPort(state, action) {
  const { port: updatedPortValue, guid } = action;
  const { sections, currentSection } = state;
  const as2ConnectionList = sections[currentSection].as2Connections;
  const as2Connection = findConnectionByGuid(as2ConnectionList, guid);
  const { port, url } = as2Connection;
  const { newValue: newUrlValue, origValue: origUrlValue } = url;
  const { protocol, hostname, path } = parse(newUrlValue || origUrlValue);

  const newPort = parseInt(updatedPortValue || defaultPort(protocolIsHttps(protocol)), 10);
  const newUrl = `${protocol}//${hostname}:${newPort}${path}`;

  const newAs2Connection = {
    ...as2Connection,
    url: {
      ...url,
      newValue: newUrl,
      changed: urlHasChanged(url.origValue, newUrl),
    },
    port: {
      ...port,
      newValue: newPort,
      changed: port.origValue !== newPort,
    },
  };

  const editedCurrentSection = updateCurrentSectionAs2(
    sections[currentSection],
    as2ConnectionList,
    newAs2Connection,
    guid
  );

  return newState(state, sections, currentSection, editedCurrentSection, guid);
}

export function setConfigurationHttps(state, action) {
  const { https: newHttps, guid } = action;
  const { sections, currentSection } = state;
  const as2ConnectionList = sections[currentSection].as2Connections;
  const as2Connection = findConnectionByGuid(as2ConnectionList, guid);
  const { https, url } = as2Connection;
  const { newValue: newUrlValue, origValue: origUrlValue } = url;
  const { host, path } = parse(newUrlValue || origUrlValue);

  const newUrlWithHttps = `${newHttps ? 'https:' : 'http:'}//${host}${path}`;

  const newAs2Connection = {
    ...as2Connection,
    url: {
      ...url,
      newValue: newUrlWithHttps,
      changed: urlHasChanged(url.origValue, newUrlWithHttps),
    },
    https: {
      ...https,
      newValue: newHttps,
      changed: https.origValue !== newHttps,
    },
  };

  const editedCurrentSection = updateCurrentSectionAs2(
    sections[currentSection],
    as2ConnectionList,
    newAs2Connection,
    guid
  );

  return newState(state, sections, currentSection, editedCurrentSection, guid);
}

export function setConfigurationUrl(state, action) {
  const { url: newUrl, guid } = action;
  const { sections, currentSection } = state;
  const as2ConnectionList = sections[currentSection].as2Connections;
  const as2Connection = findConnectionByGuid(as2ConnectionList, guid);
  const { url, https, port } = as2Connection;
  const newEnforcedUrl = enforceUrlStructure(newUrl);
  const { port: urlPort, protocol } = parse(newEnforcedUrl);
  const newHttps = protocolIsHttps(protocol);
  const newPort = parseInt(urlPort || DEFAULT_PORT, 10);

  const newAs2Connection = {
    ...as2Connection,
    url: {
      ...url,
      newValue: newEnforcedUrl,
      changed: urlHasChanged(url.origValue, newEnforcedUrl),
    },
    https: {
      ...https,
      newValue: newHttps,
      changed: https.origValue !== newHttps,
    },
    port: {
      ...port,
      newValue: newPort,
      changed: port.origValue !== newPort,
    },
  };

  const editedCurrentSection = updateCurrentSectionAs2(
    sections[currentSection],
    as2ConnectionList,
    newAs2Connection,
    guid
  );

  return newState(state, sections, currentSection, editedCurrentSection, guid);
}

export function setMigratedToPulse(state, action) {
  const { connectionId, value } = action.payload;

  const as2ConnectionList = state.sections[state.currentSection].as2Connections;
  const as2Connection = findConnectionByGuid(as2ConnectionList, connectionId);
  if (!as2Connection) return state;

  const migratedToPulse = as2Connection.migratedToPulse ?? {origValue: false}

  const updatedMigratedField = {
    ...migratedToPulse,
    newValue: value,
    changed: migratedToPulse.origValue !== value,
  };

  const newAs2Connection = {
    ...as2Connection,
    migratedToPulse: updatedMigratedField,
  };

  const editedCurrentSection = updateCurrentSectionAs2(
    state.sections[state.currentSection],
    as2ConnectionList,
    newAs2Connection,
    connectionId
  );

  return newState(state, state.sections, state.currentSection, editedCurrentSection, connectionId);
}

export function setChubSystemCertificate(state, action) {
  const {
    chubSystemCertificate: newChubCertificate,
    chubSystemCertificateGUID: newChubCertificateGUID,
    isModified,
    guid,
  } = action;
  const { currentSection, sections } = state;
  const as2ConnectionList = sections[currentSection].as2Connections;
  const as2Connection = findConnectionByGuid(as2ConnectionList, guid);
  const { chubCertificate, chubCertificateGUID } = as2Connection;

  const newAs2Connection = {
    ...as2Connection,
    chubCertificate: {
      ...chubCertificate,
      newValue: newChubCertificate,
      changed: isModified,
    },
    chubCertificateGUID: {
      ...chubCertificateGUID,
      newValue: newChubCertificateGUID,
      changed: isModified,
    },
  };

  const editedCurrentSection = updateCurrentSectionAs2(
    sections[currentSection],
    as2ConnectionList,
    newAs2Connection,
    guid
  );

  return newState(state, sections, currentSection, editedCurrentSection, guid);
}

export function setChubSystemIdentifier(state, action) {
  const { chubSystemIdentifier: newChubAs2Id, chubSystemIdentifierGUID: newChubAs2IdGUID, isModified, guid } = action;
  const { currentSection, sections } = state;
  const as2ConnectionList = sections[currentSection].as2Connections;
  const as2Connection = findConnectionByGuid(as2ConnectionList, guid);
  const { chubAs2Id, chubAs2IdGUID } = as2Connection;

  const newAs2Connection = {
    ...as2Connection,
    chubAs2Id: {
      ...chubAs2Id,
      newValue: newChubAs2Id,
      changed: isModified,
    },
    chubAs2IdGUID: {
      ...chubAs2IdGUID,
      newValue: newChubAs2IdGUID,
      changed: isModified,
    },
  };

  const editedCurrentSection = updateCurrentSectionAs2(
    sections[currentSection],
    as2ConnectionList,
    newAs2Connection,
    guid
  );

  return newState(state, sections, currentSection, editedCurrentSection, guid);
}

export function setAs2Id(state, action) {
  const { as2Id: newAs2Id, guid } = action;
  const { sections, currentSection } = state;
  const as2ConnectionList = sections[currentSection].as2Connections;
  const as2Connection = findConnectionByGuid(as2ConnectionList, guid);
  const { as2Id } = as2Connection;

  const newAs2Connection = {
    ...as2Connection,
    as2Id: {
      ...as2Id,
      newValue: newAs2Id,
      changed: as2Id.origValue !== newAs2Id,
    },
  };

  const editedCurrentSection = updateCurrentSectionAs2(
    sections[currentSection],
    as2ConnectionList,
    newAs2Connection,
    guid
  );

  return newState(state, sections, currentSection, editedCurrentSection, guid);
}

export function setConfigurationMicAlgorithm(state, action) {
  const { micAlgorithm: newMicAlgorithm, guid } = action;
  const { sections, currentSection } = state;
  const as2ConnectionList = sections[currentSection].as2Connections;
  const as2Connection = findConnectionByGuid(as2ConnectionList, guid);
  const { micAlgorithm } = as2Connection;

  const newAs2Connection = {
    ...as2Connection,
    micAlgorithm: {
      ...micAlgorithm,
      newValue: newMicAlgorithm,
      changed: micAlgorithm.origValue !== newMicAlgorithm,
    },
  };

  const editedCurrentSection = updateCurrentSectionAs2(
    sections[currentSection],
    as2ConnectionList,
    newAs2Connection,
    guid
  );

  return newState(state, sections, currentSection, editedCurrentSection, guid);
}

export function setAllowDuplicateMessageIds(state, action) {
  const { allowDuplicateMessageIds: newAllowDuplicateMessageIds, guid } = action;
  const { sections, currentSection } = state;
  const as2ConnectionList = sections[currentSection].as2Connections;
  const as2Connection = findConnectionByGuid(as2ConnectionList, guid);
  const { allowDuplicateMessageIds } = as2Connection;

  const newAs2Connection = {
    ...as2Connection,
    allowDuplicateMessageIds: {
      ...allowDuplicateMessageIds,
      newValue: newAllowDuplicateMessageIds,
      changed: allowDuplicateMessageIds.origValue !== newAllowDuplicateMessageIds,
    },
  };

  const editedCurrentSection = updateCurrentSectionAs2(
    sections[currentSection],
    as2ConnectionList,
    newAs2Connection,
    guid
  );

  return newState(state, sections, currentSection, editedCurrentSection, guid);
}

export function setAuthenticationCertHref(state, action) {
  //const { authenticationCertHref: newAuthenticationCertHref, guid } = action;
  const {
    authenticationCertificate: newAuthenticationCertificate,
    authenticationCertificateGUID: newAuthenticationCertificateGUID,
    isModified,
    guid,
  } = action;
  const { sections, currentSection } = state;
  const as2ConnectionList = sections[currentSection].as2Connections;
  const as2Connection = findConnectionByGuid(as2ConnectionList, guid);
  const { authenticationCertHref, authenticationCertPassword } = as2Connection;

  const newAs2Connection = {
    ...as2Connection,
    authenticationCertHref: {
      ...authenticationCertHref,
      newValue: newAuthenticationCertificate,
      changed: isModified,
    },
    authenticationCertPassword: {
      ...authenticationCertPassword,
      newValue: newAuthenticationCertificateGUID,
      changed: isModified,
    },
  };

  const editedCurrentSection = updateCurrentSectionAs2(
    sections[currentSection],
    as2ConnectionList,
    newAs2Connection,
    guid
  );

  return newState(state, sections, currentSection, editedCurrentSection, guid);
}

export function setAuthenticationCertPassword(state, action) {
  const { authenticationCertPassword: newAuthenticationCertPassword, guid } = action;
  const { sections, currentSection } = state;
  const as2ConnectionList = sections[currentSection].as2Connections;
  const as2Connection = findConnectionByGuid(as2ConnectionList, guid);
  const { authenticationCertPassword } = as2Connection;

  const newAs2Connection = {
    ...as2Connection,
    authenticationCertPassword: {
      ...authenticationCertPassword,
      newValue: newAuthenticationCertPassword,
      changed: authenticationCertPassword.origValue !== newAuthenticationCertPassword,
    },
  };

  const editedCurrentSection = updateCurrentSectionAs2(
    sections[currentSection],
    as2ConnectionList,
    newAs2Connection,
    guid
  );

  return newState(state, sections, currentSection, editedCurrentSection, guid);
}

export function setSecurityAuthenticationPassword(state, action) {
  const { securityAuthenticationPassword: newSecurityAuthenticationPassword, guid } = action;
  const { sections, currentSection } = state;
  const as2ConnectionList = sections[currentSection].as2Connections;
  const as2Connection = findConnectionByGuid(as2ConnectionList, guid);
  const { securityAuthenticationPassword } = as2Connection;

  const newAs2Connection = {
    ...as2Connection,
    securityAuthenticationPassword: {
      ...securityAuthenticationPassword,
      newValue: newSecurityAuthenticationPassword,
      changed: securityAuthenticationPassword.origValue !== newSecurityAuthenticationPassword,
    },
  };

  const editedCurrentSection = updateCurrentSectionAs2(
    sections[currentSection],
    as2ConnectionList,
    newAs2Connection,
    guid
  );

  return newState(state, sections, currentSection, editedCurrentSection, guid);
}

export function setSecurityAuthenticationRealm(state, action) {
  const { securityAuthenticationRealm: newSecurityAuthenticationRealm, guid } = action;
  const { sections, currentSection } = state;
  const as2ConnectionList = sections[currentSection].as2Connections;
  const as2Connection = findConnectionByGuid(as2ConnectionList, guid);
  const { securityAuthenticationRealm } = as2Connection;

  const newAs2Connection = {
    ...as2Connection,
    securityAuthenticationRealm: {
      ...securityAuthenticationRealm,
      newValue: newSecurityAuthenticationRealm,
      changed: securityAuthenticationRealm.origValue !== newSecurityAuthenticationRealm,
    },
  };

  const editedCurrentSection = updateCurrentSectionAs2(
    sections[currentSection],
    as2ConnectionList,
    newAs2Connection,
    guid
  );

  return newState(state, sections, currentSection, editedCurrentSection, guid);
}

export function setSecurityAuthenticationType(state, action) {
  const { securityAuthenticationType: newSecurityAuthenticationType, guid } = action;
  const { sections, currentSection } = state;
  const as2ConnectionList = sections[currentSection].as2Connections;
  const as2Connection = findConnectionByGuid(as2ConnectionList, guid);
  const { securityAuthenticationType } = as2Connection;

  const newAs2Connection = {
    ...as2Connection,
    securityAuthenticationType: {
      ...securityAuthenticationType,
      newValue: newSecurityAuthenticationType,
      changed: securityAuthenticationType.origValue !== newSecurityAuthenticationType,
    },
  };

  const editedCurrentSection = updateCurrentSectionAs2(
    sections[currentSection],
    as2ConnectionList,
    newAs2Connection,
    guid
  );

  return newState(state, sections, currentSection, editedCurrentSection, guid);
}

export function setSecurityAuthenticationUsername(state, action) {
  const { securityAuthenticationUsername: newSecurityAuthenticationUsername, guid } = action;
  const { sections, currentSection } = state;
  const as2ConnectionList = sections[currentSection].as2Connections;
  const as2Connection = findConnectionByGuid(as2ConnectionList, guid);
  const { securityAuthenticationUsername } = as2Connection;

  const newAs2Connection = {
    ...as2Connection,
    securityAuthenticationUsername: {
      ...securityAuthenticationUsername,
      newValue: newSecurityAuthenticationUsername,
      changed: securityAuthenticationUsername.origValue !== newSecurityAuthenticationUsername,
    },
  };

  const editedCurrentSection = updateCurrentSectionAs2(
    sections[currentSection],
    as2ConnectionList,
    newAs2Connection,
    guid
  );

  return newState(state, sections, currentSection, editedCurrentSection, guid);
}

export function setConnectionTimeout(state, action) {
  let { connectionTimeout: newConnectionTimeout } = action;
  const { guid } = action;

  if (newConnectionTimeout === '') {
    newConnectionTimeout = null;
  }

  const { sections, currentSection } = state;
  const as2ConnectionList = sections[currentSection].as2Connections;
  const as2Connection = findConnectionByGuid(as2ConnectionList, guid);
  const { connectionTimeout } = as2Connection;

  const newAs2Connection = {
    ...as2Connection,
    connectionTimeout: {
      ...connectionTimeout,
      newValue: newConnectionTimeout,
      changed: connectionTimeout.origValue !== newConnectionTimeout,
    },
  };

  const editedCurrentSection = updateCurrentSectionAs2(
    sections[currentSection],
    as2ConnectionList,
    newAs2Connection,
    guid
  );

  return newState(state, sections, currentSection, editedCurrentSection, guid);
}

export function setSslAllowLegacyRenegotiation(state, action) {
  const { sslAllowLegacyRenegotiation: newSslAllowLegacyRenegotiation, guid } = action;
  const { sections, currentSection } = state;
  const as2ConnectionList = sections[currentSection].as2Connections;
  const as2Connection = findConnectionByGuid(as2ConnectionList, guid);
  const { sslAllowLegacyRenegotiation } = as2Connection;

  const newAs2Connection = {
    ...as2Connection,
    sslAllowLegacyRenegotiation: {
      ...sslAllowLegacyRenegotiation,
      newValue: newSslAllowLegacyRenegotiation,
      changed: sslAllowLegacyRenegotiation.origValue !== newSslAllowLegacyRenegotiation,
    },
  };

  const editedCurrentSection = updateCurrentSectionAs2(
    sections[currentSection],
    as2ConnectionList,
    newAs2Connection,
    guid
  );

  return newState(state, sections, currentSection, editedCurrentSection, guid);
}

export function setSslCipher(state, action) {
  const { sslCipher: newSslCipher, guid } = action;
  const { sections, currentSection } = state;
  const as2ConnectionList = sections[currentSection].as2Connections;
  const as2Connection = findConnectionByGuid(as2ConnectionList, guid);
  const { sslCipher } = as2Connection;

  const newAs2Connection = {
    ...as2Connection,
    sslCipher: {
      ...sslCipher,
      newValue: newSslCipher,
      changed: sslCipher.origValue !== newSslCipher,
    },
  };

  const editedCurrentSection = updateCurrentSectionAs2(
    sections[currentSection],
    as2ConnectionList,
    newAs2Connection,
    guid
  );

  return newState(state, sections, currentSection, editedCurrentSection, guid);
}

export function setSslMinimumProtocolVersion(state, action) {
  const { sslMinimumProtocolVersion: newSslMinimumProtocolVersion, guid } = action;
  const { sections, currentSection } = state;
  const as2ConnectionList = sections[currentSection].as2Connections;
  const as2Connection = findConnectionByGuid(as2ConnectionList, guid);
  const { sslMinimumProtocolVersion } = as2Connection;

  const newAs2Connection = {
    ...as2Connection,
    sslMinimumProtocolVersion: {
      ...sslMinimumProtocolVersion,
      newValue: newSslMinimumProtocolVersion,
      changed: sslMinimumProtocolVersion.origValue !== newSslMinimumProtocolVersion,
    },
  };

  const editedCurrentSection = updateCurrentSectionAs2(
    sections[currentSection],
    as2ConnectionList,
    newAs2Connection,
    guid
  );

  return newState(state, sections, currentSection, editedCurrentSection, guid);
}

export function setSslMaximumProtocolVersion(state, action) {
  const { sslMaximumProtocolVersion: newSslMaximumProtocolVersion, guid } = action;
  const { sections, currentSection } = state;
  const as2ConnectionList = sections[currentSection].as2Connections;
  const as2Connection = findConnectionByGuid(as2ConnectionList, guid);
  const { sslMaximumProtocolVersion } = as2Connection;

  const newAs2Connection = {
    ...as2Connection,
    sslMaximumProtocolVersion: {
      ...sslMaximumProtocolVersion,
      newValue: newSslMaximumProtocolVersion,
      changed: sslMaximumProtocolVersion.origValue !== newSslMaximumProtocolVersion,
    },
  };

  const editedCurrentSection = updateCurrentSectionAs2(
    sections[currentSection],
    as2ConnectionList,
    newAs2Connection,
    guid
  );

  return newState(state, sections, currentSection, editedCurrentSection, guid);
}

export function setOutgoingReceiptAsyncResends(state, action) {
  let { outgoingReceiptAsyncResends: newOutgoingReceiptAsyncResends } = action;
  const { guid } = action;

  if (newOutgoingReceiptAsyncResends === '') {
    newOutgoingReceiptAsyncResends = null;
  }
  const { sections, currentSection } = state;
  const as2ConnectionList = sections[currentSection].as2Connections;
  const as2Connection = findConnectionByGuid(as2ConnectionList, guid);
  const { outgoingReceiptAsyncResends } = as2Connection;

  const newAs2Connection = {
    ...as2Connection,
    outgoingReceiptAsyncResends: {
      ...outgoingReceiptAsyncResends,
      newValue: newOutgoingReceiptAsyncResends,
      changed: outgoingReceiptAsyncResends.origValue !== newOutgoingReceiptAsyncResends,
    },
  };

  const editedCurrentSection = updateCurrentSectionAs2(
    sections[currentSection],
    as2ConnectionList,
    newAs2Connection,
    guid
  );

  return newState(state, sections, currentSection, editedCurrentSection, guid);
}

export function setOutgoingReceiptAsyncRetryDelay(state, action) {
  let { outgoingReceiptAsyncRetryDelay: newOutgoingReceiptAsyncRetryDelay } = action;
  const { guid } = action;

  if (newOutgoingReceiptAsyncRetryDelay === '') {
    newOutgoingReceiptAsyncRetryDelay = null;
  }
  const { sections, currentSection } = state;
  const as2ConnectionList = sections[currentSection].as2Connections;
  const as2Connection = findConnectionByGuid(as2ConnectionList, guid);
  const { outgoingReceiptAsyncRetryDelay } = as2Connection;

  const newAs2Connection = {
    ...as2Connection,
    outgoingReceiptAsyncRetryDelay: {
      ...outgoingReceiptAsyncRetryDelay,
      newValue: newOutgoingReceiptAsyncRetryDelay,
      changed: outgoingReceiptAsyncRetryDelay.origValue !== newOutgoingReceiptAsyncRetryDelay,
    },
  };

  const editedCurrentSection = updateCurrentSectionAs2(
    sections[currentSection],
    as2ConnectionList,
    newAs2Connection,
    guid
  );

  return newState(state, sections, currentSection, editedCurrentSection, guid);
}

export function setOutgoingReceiptAsyncTimeout(state, action) {
  let { outgoingReceiptAsyncTimeout: newOutgoingReceiptAsyncTimeout } = action;
  const { guid } = action;

  if (newOutgoingReceiptAsyncTimeout === '') {
    newOutgoingReceiptAsyncTimeout = null;
  }
  const { sections, currentSection } = state;
  const as2ConnectionList = sections[currentSection].as2Connections;
  const as2Connection = findConnectionByGuid(as2ConnectionList, guid);
  const { outgoingReceiptAsyncTimeout } = as2Connection;

  const newAs2Connection = {
    ...as2Connection,
    outgoingReceiptAsyncTimeout: {
      ...outgoingReceiptAsyncTimeout,
      newValue: newOutgoingReceiptAsyncTimeout,
      changed: outgoingReceiptAsyncTimeout.origValue !== newOutgoingReceiptAsyncTimeout,
    },
  };

  const editedCurrentSection = updateCurrentSectionAs2(
    sections[currentSection],
    as2ConnectionList,
    newAs2Connection,
    guid
  );
  return newState(state, sections, currentSection, editedCurrentSection, guid);
}

export function setMailboxName(state, action) {
  const { mailboxName: newMailboxName, guid } = action;
  const { sections, currentSection } = state;
  const as2ConnectionList = sections[currentSection].as2Connections;
  const as2Connection = findConnectionByGuid(as2ConnectionList, guid);
  const { mailboxName } = as2Connection;

  const newAs2Connection = {
    ...as2Connection,
    mailboxName: {
      ...mailboxName,
      newValue: newMailboxName,
      changed: mailboxName.origValue !== newMailboxName,
    },
  };

  const editedCurrentSection = updateCurrentSectionAs2(
    sections[currentSection],
    as2ConnectionList,
    newAs2Connection,
    guid
  );

  return newState(state, sections, currentSection, editedCurrentSection, guid);
}

function findConnectionByGuid(as2ConnectionList, guid) {
  return as2ConnectionList.find((connection) => connection.mailboxGUID === guid);
}

export function setAS2GatewayEnabled(state, action) {
  const { isEnabled, guid } = action;
  const { sections, currentSection } = state;
  const as2ConnectionList = sections[currentSection].as2Connections;
  const as2Connection = findConnectionByGuid(as2ConnectionList, guid);
  const { sendOutboundThroughAS2Forwarder } = as2Connection;

  const newAs2Connection = {
    ...as2Connection,
    sendOutboundThroughAS2Forwarder: {
      ...sendOutboundThroughAS2Forwarder,
      newValue: isEnabled,
      changed: sendOutboundThroughAS2Forwarder.origValue !== isEnabled,
    },
  };

  const editedCurrentSection = updateCurrentSectionAs2(
    sections[currentSection],
    as2ConnectionList,
    newAs2Connection,
    guid
  );

  return newState(state, sections, currentSection, editedCurrentSection, guid);
}

export function setAS2Gateway(state, action) {
  const { as2Gateway: newAS2Gateway, guid } = action;
  const { sections, currentSection } = state;
  const as2ConnectionList = sections[currentSection].as2Connections;
  const as2Connection = findConnectionByGuid(as2ConnectionList, guid);
  const { as2Gateway } = as2Connection;

  const newAs2Connection = {
    ...as2Connection,
    as2Gateway: {
      ...as2Gateway,
      newValue: newAS2Gateway,
      changed: true,
    },
  };

  const editedCurrentSection = updateCurrentSectionAs2(
    sections[currentSection],
    as2ConnectionList,
    newAs2Connection,
    guid
  );

  return newState(state, sections, currentSection, editedCurrentSection, guid);
}
