import {
  RECEIVE_CONFIGURATION_VIEW_MODEL,
  RECEIVE_CONFIGURATION_VIEW_MODEL_UNDOABLE,
  RESET_SECTION_CAN_SAVE,
  SET_AS2_ID,
  SET_MAILBOX_NAME,
  SET_CHUB_SYSTEM_IDENTIFIER,
  SET_CHUB_SYSTEM_IDENTIFIER_LIST,
  SET_AS2_FILE_ROUTE_LIST,
  SET_SFTP_FILE_ROUTE_LIST,
  SET_PARTNER_SFTP_FILE_ROUTE_LIST,
  SET_CLIENT_INFO,
  SET_CURRENT_SECTION,
  SET_CURRENT_SECTION_UNDOABLE,
  TOGGLE_VIEW_PARTNER_CERTIFICATE,
  TOGGLE_FILE_ROUTE_VISIBILITY,
  SET_CONFIGURATION_URL,
  SET_CONFIGURATION_PORT,
  SET_CONFIGURATION_HTTPS,
  SET_CHUB_SYSTEM_CERTIFICATE_LIST,
  SET_CHUB_SYSTEM_CERTIFICATE,
  SET_FILE_ROUTE_KEY,
  SET_CONNECTION_ENABLED,
  SET_FILE_ROUTE_REGEX,
  SET_FILE_ROUTE_EXTENSION,
  SET_FILE_ROUTE_ADDITIONAL_ROUTING_DATA,
  SET_SFTP_USERNAME,
  SET_FILE_ROUTE_MD5_VERIFIED,
  SET_SUB_PATH,
  ADD_SUB_PATH,
  DELETE_SUB_PATH,
  ADD_NEW_AS2_FILE_ROUTE,
  ADD_NEW_SFTP_FILE_ROUTE,
  ADD_NEW_PARTNER_SFTP_FILE_ROUTE,
  DELETE_FILE_ROUTE,
  ADD_AS2_CONNECTION,
  ADD_SFTP_CONNECTION,
  ADD_PARTNER_SFTP_CONNECTION,
  COPY_SECTION,
  SET_COPYABLE_ENVIRONMENTS,
  SET_CONFIGURATION_MICALGORITHM,
  SET_VERSION_NAME,
  SET_MIC_ALGORITHM_LIST,
  ADD_RELATIONSHIP,
  SET_ALLOW_DUPLICATE_MESSAGE_IDS,
  SET_AUTHENTICATION_CERT_HREF,
  SET_AUTHENTICATION_CERT_PASSWORD,
  SET_SECURITY_AUTHENTICATION_PASSWORD,
  SET_SECURITY_AUTHENTICATION_REALM,
  SET_SECURITY_AUTHENTICATION_TYPE,
  SET_SECURITY_AUTHENTICATION_USERNAME,
  SET_CONNECTION_TIMEOUT,
  SET_SSL_ALLOW_LEGACY_RENEGOTIATION,
  SET_SSL_CIPHER,
  SET_SSL_MINIMUM_PROTOCOL_VERSION,
  SET_SSL_MAXIMUM_PROTOCOL_VERSION,
  SET_OUTGOING_RECEIPT_ASYNC_RESENDS,
  SET_OUTGOING_RECEIPT_ASYNC_RETRY_DELAY,
  SET_OUTGOING_RECEIPT_ASYNC_TIMEOUT,
  DELETE_AS2_CONNECTION,
  DELETE_PARTNER_SFTP_CONNECTION,
  RESET_STATE,
  SET_FILE_ROUTE_CONTENT_TYPE,
  SET_AS2GATEWAY_ENABLED,
  SET_AS2GATEWAY,
  SET_PARTNER_SFTP_CONFIGURATION_URL,
  SET_PARTNER_SFTP_USERNAME,
  SET_PARTNER_SFTP_CONFIGURATION_PORT,
  SET_PARTNER_SFTP_PASSWORD,
  SET_CLIENT_AUTH_CERT,
  SET_CLIENT_AUTH_CERT_PASSWORD,
  SET_CLIENT_AUTH_PRIVATE_KEY_FILE_PASSWORD,
  SET_CLIENT_AUTH_PRIVATE_KEY_FILE,
  TOGGLE_VIEW_PARTNER_SIGNING_CERTIFICATE_OVERRIDE,
} from '../ClientConfigurationActionTypes';

import {
  setFileRouteMd5Verified,
  setFileRouteKey,
  setFileRouteRegex,
  setFileRouteExtension,
  setFileRouteContentType,
  setFileRouteAdditionalRoutingData,
  toggleFileRouteVisibility,
  addNewAs2FileRoute,
  deleteFileRoute,
  addNewSftpFileRoute,
  addNewPartnerSftpFileRoute,
} from './configurationViewModelReducers/fileRouteReducers';
import {
  toggleViewPartnerCertificate,
  toggleViewPartnerSigningCertificateOverride,
  setConfigurationPort,
  setConfigurationHttps,
  setConfigurationUrl,
  setMigratedToPulse,
  setConfigurationMicAlgorithm,
  setChubSystemCertificate,
  setChubSystemIdentifier,
  setAs2Id,
  setMailboxName,
  setAllowDuplicateMessageIds,
  setAuthenticationCertHref,
  setAuthenticationCertPassword,
  setSecurityAuthenticationPassword,
  setSecurityAuthenticationRealm,
  setSecurityAuthenticationType,
  setSecurityAuthenticationUsername,
  setConnectionTimeout,
  setSslAllowLegacyRenegotiation,
  setSslCipher,
  setSslMinimumProtocolVersion,
  setSslMaximumProtocolVersion,
  setOutgoingReceiptAsyncResends,
  setOutgoingReceiptAsyncRetryDelay,
  setOutgoingReceiptAsyncTimeout,
  setAS2GatewayEnabled,
  setAS2Gateway,
} from './configurationViewModelReducers/as2ConfigurationReducers';
import {
  setClientInfo,
  setChubSystemIdentifierList,
  setAS2FileRouteList,
  setSFTPFileRouteList,
  setPartnerSFTPFileRouteList,
  setChubSystemCertificateList,
  setCopyableEnvironments,
  setMicAlgorithmList,
} from './configurationViewModelReducers/entryReducers';
import {
  setConnectionEnabled,
  addSftpConnection,
  addPartnerSftpConnection,
  addAs2Connection,
  copySection,
  deleteAs2Connection,
  deletePartnerSftpConnection,
  setVersionName,
} from './configurationViewModelReducers/configurationReducers';
import {
  receiveConfigurationViewModel,
  resetSectionCanSave,
} from './configurationViewModelReducers/afterFetchReducers';
import { setSftpUsername } from './configurationViewModelReducers/sftpConfigurationReducers';
import { setSubPath, deleteSubPath, addSubPath } from './configurationViewModelReducers/subPathReducers';
import { addRelationship } from './configurationViewModelReducers/addRelationship';
import {
  setPartnerSftpConfigurationUrl,
  setPartnerSftpUsername,
  setPartnerSftpConfigurationPort,
  setPartnerSftpPassword,
  setClientAuthCert,
  setClientAuthCertPassword,
  setClientAuthPrivateKeyFile,
  setClientAuthPrivateKeyFilePassword,
} from './configurationViewModelReducers/partnerSftpConfigurationReducers';

const initialState = {
  clientName: '',
  clientGUID: '',
  viewPartnerCertificate: false,
  viewPartnerSigningCertificateOverride: false,
  chubSystemIdentifiers: [],
  as2FileRouteTypes: {},
  sftpFileRouteTypes: {},
  partnerSftpFileRouteTypes: {},
  currentSection: '', // UI State: Pass Down On initial Load based on permissions
  sections: {},
  serverMessages: [],
  copyableEnvironments: [],
  environmentList: [],
};

function setCurrentSection(state, action) {
  const { currentSection } = action;
  return {
    ...state,
    currentSection,
  };
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_SUB_PATH:
      return deleteSubPath(state, action);
    case ADD_SUB_PATH:
      return addSubPath(state, action);
    case SET_SUB_PATH:
      return setSubPath(state, action);
    case SET_FILE_ROUTE_MD5_VERIFIED:
      return setFileRouteMd5Verified(state, action);
    case SET_FILE_ROUTE_KEY:
      return setFileRouteKey(state, action);
    case SET_FILE_ROUTE_REGEX:
      return setFileRouteRegex(state, action);
    case SET_FILE_ROUTE_EXTENSION:
      return setFileRouteExtension(state, action);
    case SET_FILE_ROUTE_CONTENT_TYPE:
      return setFileRouteContentType(state, action);
    case SET_FILE_ROUTE_ADDITIONAL_ROUTING_DATA:
      return setFileRouteAdditionalRoutingData(state, action);
    case TOGGLE_FILE_ROUTE_VISIBILITY:
      return toggleFileRouteVisibility(state, action);
    case TOGGLE_VIEW_PARTNER_CERTIFICATE:
      return toggleViewPartnerCertificate(state, action);
    case TOGGLE_VIEW_PARTNER_SIGNING_CERTIFICATE_OVERRIDE:
      return toggleViewPartnerSigningCertificateOverride(state, action);
    case SET_CONFIGURATION_PORT:
      return setConfigurationPort(state, action);
    case SET_CONFIGURATION_HTTPS:
      return setConfigurationHttps(state, action);
    case SET_CONFIGURATION_URL:
      return setConfigurationUrl(state, action);
    case 'SET_MIGRATED_TO_PULSE':
      return setMigratedToPulse(state, action);
    case SET_PARTNER_SFTP_CONFIGURATION_URL:
      return setPartnerSftpConfigurationUrl(state, action);
    case SET_PARTNER_SFTP_CONFIGURATION_PORT:
      return setPartnerSftpConfigurationPort(state, action);
    case SET_PARTNER_SFTP_USERNAME:
      return setPartnerSftpUsername(state, action);
    case SET_PARTNER_SFTP_PASSWORD:
      return setPartnerSftpPassword(state, action);
    case SET_CLIENT_AUTH_CERT:
      return setClientAuthCert(state, action);
    case SET_CLIENT_AUTH_CERT_PASSWORD:
      return setClientAuthCertPassword(state, action);
    case SET_CLIENT_AUTH_PRIVATE_KEY_FILE:
      return setClientAuthPrivateKeyFile(state, action);
    case SET_CLIENT_AUTH_PRIVATE_KEY_FILE_PASSWORD:
      return setClientAuthPrivateKeyFilePassword(state, action);
    case SET_CONFIGURATION_MICALGORITHM:
      return setConfigurationMicAlgorithm(state, action);
    case SET_CHUB_SYSTEM_CERTIFICATE:
      return setChubSystemCertificate(state, action);
    case ADD_NEW_AS2_FILE_ROUTE:
      return addNewAs2FileRoute(state, action);
    case ADD_NEW_SFTP_FILE_ROUTE:
      return addNewSftpFileRoute(state);
    case ADD_NEW_PARTNER_SFTP_FILE_ROUTE:
      return addNewPartnerSftpFileRoute(state, action);
    case DELETE_FILE_ROUTE:
      return deleteFileRoute(state, action);
    case SET_CHUB_SYSTEM_IDENTIFIER:
      return setChubSystemIdentifier(state, action);
    case SET_AS2_ID:
      return setAs2Id(state, action);
    case SET_MAILBOX_NAME:
      return setMailboxName(state, action);
    case SET_SFTP_USERNAME:
      return setSftpUsername(state, action);
    case SET_CONNECTION_ENABLED:
      return setConnectionEnabled(state, action);
    case SET_CLIENT_INFO:
      return setClientInfo(state, action);
    case SET_CHUB_SYSTEM_IDENTIFIER_LIST:
      return setChubSystemIdentifierList(state, action);
    case SET_AS2_FILE_ROUTE_LIST:
      return setAS2FileRouteList(state, action);
    case SET_SFTP_FILE_ROUTE_LIST:
      return setSFTPFileRouteList(state, action);
    case SET_PARTNER_SFTP_FILE_ROUTE_LIST:
      return setPartnerSFTPFileRouteList(state, action);
    case SET_CHUB_SYSTEM_CERTIFICATE_LIST:
      return setChubSystemCertificateList(state, action);
    case SET_MIC_ALGORITHM_LIST:
      return setMicAlgorithmList(state, action);
    case SET_CURRENT_SECTION:
    case SET_CURRENT_SECTION_UNDOABLE:
      return setCurrentSection(state, action);
    case RECEIVE_CONFIGURATION_VIEW_MODEL:
    case RECEIVE_CONFIGURATION_VIEW_MODEL_UNDOABLE:
      return receiveConfigurationViewModel(state, action);
    case RESET_SECTION_CAN_SAVE:
      return resetSectionCanSave(state, action);
    case ADD_AS2_CONNECTION:
      return addAs2Connection(state, action);
    case ADD_SFTP_CONNECTION:
      return addSftpConnection(state);
    case ADD_PARTNER_SFTP_CONNECTION:
      return addPartnerSftpConnection(state);
    case SET_VERSION_NAME:
      return setVersionName(state, action);
    case COPY_SECTION:
      return copySection(state, action);
    case SET_COPYABLE_ENVIRONMENTS:
      return setCopyableEnvironments(state, action);
    case ADD_RELATIONSHIP:
      return addRelationship(state, action);
    case SET_ALLOW_DUPLICATE_MESSAGE_IDS:
      return setAllowDuplicateMessageIds(state, action);
    case SET_AUTHENTICATION_CERT_HREF:
      return setAuthenticationCertHref(state, action);
    case SET_AUTHENTICATION_CERT_PASSWORD:
      return setAuthenticationCertPassword(state, action);
    case SET_SECURITY_AUTHENTICATION_PASSWORD:
      return setSecurityAuthenticationPassword(state, action);
    case SET_SECURITY_AUTHENTICATION_REALM:
      return setSecurityAuthenticationRealm(state, action);
    case SET_SECURITY_AUTHENTICATION_TYPE:
      return setSecurityAuthenticationType(state, action);
    case SET_SECURITY_AUTHENTICATION_USERNAME:
      return setSecurityAuthenticationUsername(state, action);
    case SET_CONNECTION_TIMEOUT:
      return setConnectionTimeout(state, action);
    case SET_SSL_ALLOW_LEGACY_RENEGOTIATION:
      return setSslAllowLegacyRenegotiation(state, action);
    case SET_SSL_CIPHER:
      return setSslCipher(state, action);
    case SET_SSL_MINIMUM_PROTOCOL_VERSION:
      return setSslMinimumProtocolVersion(state, action);
    case SET_SSL_MAXIMUM_PROTOCOL_VERSION:
      return setSslMaximumProtocolVersion(state, action);
    case SET_OUTGOING_RECEIPT_ASYNC_RESENDS:
      return setOutgoingReceiptAsyncResends(state, action);
    case SET_OUTGOING_RECEIPT_ASYNC_RETRY_DELAY:
      return setOutgoingReceiptAsyncRetryDelay(state, action);
    case SET_OUTGOING_RECEIPT_ASYNC_TIMEOUT:
      return setOutgoingReceiptAsyncTimeout(state, action);
    case DELETE_AS2_CONNECTION:
      return deleteAs2Connection(state, action);
    case DELETE_PARTNER_SFTP_CONNECTION:
      return deletePartnerSftpConnection(state, action);
    case SET_AS2GATEWAY_ENABLED:
      return setAS2GatewayEnabled(state, action);
    case SET_AS2GATEWAY:
      return setAS2Gateway(state, action);
    case RESET_STATE:
      return initialState;
  }
  return state;
};

export default reducer;
